import React, { useEffect, useState, useContext } from 'react'
import MyNFTLoader from '../MyNFTs/MyNFTLoader';
import NFTList from "../MyNFTs/NFTList";
import { fetchSellerNft, fetchUserNft } from "../../../Api/Solana/nft";
import { Identicon } from '../Nft/Identicon';
import { Link } from 'react-router-dom';
import Marquee from 'react-fast-marquee';
import { useTranslation } from 'react-i18next';
import { MetaplexContext } from "../../../Providers/MetaplexContextProvider";
import { AuctionHouseContext } from "../../../Providers/AuctionHouseContextProvider";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import AuthorDetailsSkeleton from './AuthorDetailsSkeleton';
import axios from 'axios';
import Swal from "sweetalert2/dist/sweetalert2.js";
import { toMetaplexFileFromBrowser } from "@metaplex-foundation/js";
import { getMessaging, getToken } from "firebase/messaging";

import { requestForToken, onMessageListener, sendMessage, sendMessageListener } from '../../../utils/firebase';
import Carousel from 'react-multi-carousel';
import { dummyCollections } from './company/Company';
import "./profile.css";

export const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 1500 }, //min:2000
    items: 3,
    slidesToSlide: 3,
  },
  desktop: {
    breakpoint: { max: 1500, min: 993 }, //max:2000
    items: 2,
    slidesToSlide: 2,//4,
  },
  // smallDesktop: {
  //   breakpoint: { max: 2000, min: 1024 },
  //   items: 2.5,
  //   slidesToSlide: 4,
  // },
  largeTablet: {
    breakpoint: { max: 992, min: 650 }, //max:1024
    items: 2,
    slidesToSlide: 2,
  },
  tablet: {
    breakpoint: { max: 650, min: 464 },
    items: 1.5,
    slidesToSlide: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1,

  }
};

export const CustomRightArrow = ({ onClick, ...rest }) => {
  const {
    onMove,
    carouselState: { currentSlide, deviceType }
  } = rest;
  // onMove means if dragging or swiping in progress.
  return <button className="fa fa-angle-right"
    aria-label="Go to next slide"
    type="button"
    style={{
      position: 'absolute',
      outline: 'none',
      transition: 'all 0.5s',
      borderRadius: '5px',
      zIndex: 10,
      border: 'none',
      background: 'rgba(0, 0, 0, 0.5)',
      minWidth: '43px',
      minHeight: '43px',
      opacity: 1,
      cursor: 1,
      right: 0,
      alignItems: 'center',
      justifyContent: 'center',
      color: "white",
    }} onClick={() => onClick()} >
  </button>;
};

export const CustomLeftArrow = ({ onClick, ...rest }) => {
  const {
    onMove,
    carouselState: { currentSlide, deviceType }
  } = rest;
  // onMove means if dragging or swiping in progress.
  return <button className="fa fa-angle-left"
    aria-label="Go to previous slide"
    type="button"
    style={{
      position: 'absolute',
      outline: 'none',
      transition: 'all 0.5s',
      borderRadius: '5px',
      zIndex: 10,
      border: 'none',
      background: 'rgba(0, 0, 0, 0.5)',
      minWidth: '43px',
      minHeight: '43px',
      opacity: 1,
      cursor: 1,
      left: 0,
      alignItems: 'center',
      justifyContent: 'center',
      color: "white"
    }} onClick={() => onClick()} >
  </button>;
};

const Authors = ({ props, publicKey }) => {

  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [userNfts, setUserNfts] = useState([]);
  const seller = props;
  const [userVerified, setUserVerified] = useState(seller === undefined || seller?.username === "Unknown User" ? false : true);
  // const [sellerState, setSellerState] = useState(false);
  const [isNftListing, setIsNftListing] = useState(false);
  const [isNftCancelling, setIsNftCancelling] = useState(false);
  const [showListingModal, setShowListingModal] = useState(false);
  const [showCancellingModal, setShowCancellingModal] = useState(false);

  const metaplex = useContext(MetaplexContext);
  const { auctionHouse, listedMintAddress, loadingAuctions, listedNfts } = useContext(AuctionHouseContext);

  useEffect(() => {
    const loadUserNfts = async () => {
      setLoading(true);
      const nfts = await fetchSellerNft(metaplex, publicKey.toString());
      if (nfts !== null && nfts !== undefined) { setUserNfts(nfts); setLoading(false); }
    };

    // call the function
    loadUserNfts()
      // make sure to catch any error
      .catch(() => {
        setLoading(false);
      });
  }, [])

  // useEffect(() => {
  //   if (seller !== undefined) {
  //     setSellerState(true)
  //   }
  // })

  return (

    <div className="author-profile-area">
      <div className="container">
        <div className="row justify-content-center">
          {/* hi i removed order-2 order-lg-0 from the classname below because the profile details showed up under the NFTs when changing to tablet/mobile mode. You look fantastic today. */}
          {seller === undefined ? <AuthorDetailsSkeleton /> :
            <div className="col-xl-3 col-lg-4 col-md-5 col-sm-6 col-10">
              <aside className="author-profile-wrap">
                <div className="author-profile-thumb">
                  {seller && (seller.profile_image !== "assets/img/test-data/default_prof.jpeg" && seller.profile_image !== '' && seller.profile_image !== undefined) ?
                    <img src={seller.profile_image} height="100%" width="100%" alt="" /> :
                    <Identicon
                      alt=""
                      style={{
                        width: '100%',
                        height: '100%',
                      }}
                      address={publicKey?.toString()}
                    />}
                  {/* <img src="assets/img/others/author_profile.png" alt="" /> */}
                </div>
                <div className="author-info">
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <h5 className="title">
                      {(seller.username === "Unknown User" || seller.username === undefined || seller.username === '') && (publicKey !== undefined && publicKey.toString().length !== 0) ? publicKey.toString().slice(0, 3) + "..." + publicKey.toString().slice(-3) : seller.username}
                      {/* <img src="assets/img/icons/star.svg" alt="" /> */}
                    </h5>
                  </div>
                  {/* <h5 className="title">Olivia Jenar <img src="assets/img/icons/star.svg" alt="" /></h5> */}
                  <p>{seller.bio !== undefined && seller.bio !== '' ? seller.bio : <></>}</p>
                </div>

                <ul className="author-collection">
                  <li>
                    <p>Owned Assets</p>
                    <span>{userNfts?.length ?? 0}</span>
                  </li>
                </ul>

                {seller.username !== 'Unknown User' ?
                  ((seller?.facebook_handle !== '' && seller?.facebook_handle !== undefined) || (seller?.twitter_handle !== '' && seller?.twitter_handle !== undefined) || (seller?.tiktok_handle !== '' && seller?.tiktok_handle !== undefined) || (seller?.youtube_handle !== '' && seller?.youtube_handle !== undefined) || (seller?.instagram_handle !== '' && seller?.instagram_handle != undefined)) &&
                  <div className="author-social">
                    <h6 className="title">Follow Social Media :</h6>
                    <ul>
                      {
                        seller?.facebook_handle !== undefined && seller?.facebook_handle !== '' ?
                          <li><a href={seller.facebook_handle} target="_blank"><div className="icon"><i className="fab fa-facebook-f" /></div> Facebook / {seller?.facebook_handle.includes('https://www.facebook.com/') ? "@" + seller?.facebook_handle.replace('https://www.facebook.com/', "").replace('/', "") : ""}</a></li>
                          : <></>}

                      {
                        seller?.twitter_handle !== undefined && seller?.twitter_handle !== '' ?
                          <li><a href={seller.twitter_handle} target="_blank"><div className="icon"><i className="fab fa-twitter" /></div> Twitter / {seller?.twitter_handle.includes('https://www.twitter.com/') ? "@" + seller?.twitter_handle.replace('https://www.twitter.com/', "").replace('/', "") : ''}</a></li>
                          : <></>
                      }
                      {
                        seller?.instagram_handle !== undefined && seller?.instagram_handle !== '' ?
                          <li><a href={seller.instagram_handle} target="_blank"><div className="icon"><i className="fab fa-instagram" /></div> Instagram / {seller?.instagram_handle.includes('https://www.instagram.com/') ? "@" + seller?.instagram_handle.replace('https://www.instagram.com/', "").replace('/', "") : ""}</a></li>
                          : <></>
                      }
                      {
                        seller?.youtube_handle !== undefined && seller?.youtube_handle !== '' ?
                          <li><a href={seller.youtube_handle} target="_blank"><div className="icon"><i className="fab fa-youtube" /></div> Youtube / {seller?.youtube_handle.includes('https://www.youtube.com/') ? "@" + seller?.youtube_handle.replace('https://www.youtube.com/', "").replace('/', "") : ""}</a></li>
                          : <></>
                      }
                      {/* HIDE DISCORD*/}
                      {/*
                              userData?.discord_handle !== undefined ?
                                <li><a href={userData.discord_handle} target="_blank"><div className="icon"><i className="fab fa-discord" /></div> Discord / @{userData?.discord_handle.replace('https://www.discord.com/', "").replace('/', "")}</a></li>
                                : <></>
                            } */}
                      {
                        seller?.tiktok_handle !== undefined && seller?.tiktok_handle !== '' ?
                          <li><a href={seller.tiktok_handle} target="_blank"><div className="icon"><i className="fab fa-tiktok" /></div> TikTok / {seller?.tiktok_handle.includes('https://www.tiktok.com/') ? "@" + seller?.tiktok_handle.replace('https://www.tiktok.com/@', "").replace('/', "") : ""}</a></li>
                          : <></>
                      }
                    </ul>

                  </div> : <></>
                  // <div className="author-social">
                  //   <h6 className="title" style={{ position: 'relative', filter: 'blur(4px)', pointerEvents: 'none', cursor: 'default' }}>Follow Social Media :</h6>

                  //   <ul>
                  //     <li style={{ position: 'relative', filter: 'blur(4px)', pointerEvents: 'none', cursor: 'default' }}><a href="/#"><div className="icon"><i className="fab fa-facebook-f" /></div> Facebook / @joys_Aoi</a></li>
                  //     <li style={{ position: 'relative', filter: 'blur(4px)', pointerEvents: 'none', cursor: 'default' }}><a href="/#"><div className="icon"><i className="fab fa-facebook-messenger" /></div> Messenger / @joys_Avi</a></li>
                  //     <li style={{ position: 'relative', filter: 'blur(4px)', pointerEvents: 'none', cursor: 'default' }}><a href="/#"><div className="icon"><i className="fab fa-whatsapp" /></div> Whatsapp / @joys_Avi</a></li>
                  //     <li style={{ position: 'relative', filter: 'blur(4px)', pointerEvents: 'none', cursor: 'default' }}><a href="/#"><div className="icon"><i className="fab fa-youtube" /></div> Youtube / @joys_Avi</a></li>
                  //   </ul>
                  // </div>
                }
              </aside>
            </div>
          }
          <div className="col-xl-9 col-lg-8">
            {/* HIDE NFT FILTER */}
            <div className="author-product-meta" >
              <ul>
                {/* <li className="active"><a href="/author-profile">All nfts</a></li>
                <li><a href="/author-profile">Virtual Worlds</a></li>
                <li><a href="/author-profile">Collectibles</a></li>
                <li><a href="/author-profile">Music</a></li> */}

              </ul>
              {/* HIDE SEARCHBAR UNTIL API IS MADE */}
              {/* <div style={{ justifyContent: 'flex-end' }}>
                <div style={{
                  width: '100%',
                  marginBottom: 10,
                  minWidth: 70,

                }}>
                  <i className="flaticon-search" style={{
                    padding: 15,
                    minWidth: 40
                    , position: 'absolute', color: 'rgb(255, 255, 255)'
                  }} />
                  <input
                    className='author-product-search'
                    placeholder='Search' type="text" style={{
                      fontSize: 16,
                      color: 'rgba(133, 133, 140, 1)',
                      background: 'rgba(57, 58, 81, 1)',
                      border: 'none',
                      paddingLeft: 40,
                    }} />
                </div>
              </div> */}

            </div>
            <div className="company-next-collection" style={{ border: '2px solid #E615D4', backgroundColor: '#1d1933 !important' }} >

              <p style={{ marginTop: '10px' }}>NEXT NFT COLLECTION:</p>
              <h2 className="company-strokeme" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>To Be Announced</h2>
              {/* <ul style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                    <li style={{ flex: 'display', flexDirection: 'column' }}><div className="company-strokeme">35</div><p>DAYS</p></li>
                    <li style={{ flex: 'display', flexDirection: 'column' }}><div className="company-strokeme">15</div><p>HOURS</p></li>
                    <li style={{ flex: 'display', flexDirection: 'column' }}><div className="company-strokeme">42</div><p>MINS</p></li>
                    <li style={{ flex: 'display', flexDirection: 'column' }}><div className="company-strokeme">09</div><p>SECS</p></li>
                  </ul> */}
            </div>
            {/* HIDE COLLECTIONS UNTIL USERS HAVE COLLECTIONS */}
            {/* <div className="row justify-content-center">
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <p className="company-collection-title">COLLECTIONS</p>
                <p className="company-collection-option">SEE MORE</p>

              </div>
              <Carousel
                infinite={true}
                responsive={responsive}
                customRightArrow={<CustomRightArrow />}
                customLeftArrow={<CustomLeftArrow />}
                ssr
              >
                {dummyCollections.map((collection, index) => {
                  return (
                    <div
                      key={index}
                    >
                      <div className="company-collection-item">
                        <img src={collection.image} alt="" />
                        <h5>{collection.name}<span>{collection.amount}</span></h5>
                      </div>
                    </div>
                  );
                })}</Carousel>
            </div> */}
            <div className="row justify-content-center" style={{ flexDirection: 'row', justifyContent: 'center' }}>
              {/* <div> */}
              {loading ? <MyNFTLoader numberOfSkeletons={8} userProfile={true} />
                : userNfts?.length === 0 && loading === false ? <div style={{ display: 'flex', justifyContent: 'center', marginTop: '100px', marginBottom: '100px' }}><h3 className="text-center my-4">No NFTs Owned</h3></div> : userNfts?.length && userNfts?.map((data) => {
                  return (
                    <div
                      // className="mx-4" 
                      className="col-xl-4 col-lg-6 col-md-5 col-sm-6 col-10"
                      // className="row justify-content-center"
                      key={data?.address}
                    >

                      <div className="top-collection-item" style={{
                        maxWidth: 300,
                        minWidth: 280
                      }}>
                        <div className="collection-item-top">
                          <ul>
                            <li className="avatar">


                              <div className="thumb">
                                {data?.creators[0] && <Identicon
                                  alt=""
                                  style={{
                                    width: '24px',
                                    height: '24px',
                                    marginRight: '10px',
                                    marginTop: '2px',
                                  }}
                                  address={data?.creators[0]?.address?.toString() ?? 'nAN'} />}
                              </div>
                              {/* <Link style={{ color: '#e039fd' }} to={{ pathname: `/author-profile/${data?.creators[0]?.address?.toString()}` }} state={data?.creators[0]?.address?.toString()}> */}
                              {/* {console.log('yooo', data?.creators[0]?.address.toString())} */}
                              By {data?.creators[0] !== undefined || data?.creators?.length !== 0 ? data?.creators[0]?.address?.toString().slice(0, 3) + "..." + (data?.creators[0]?.address?.toString().slice(-3)) : 'Undefined'}
                              {/* </Link> */}
                            </li>
                          </ul>
                        </div>
                        <div className="collection-item-thumb">
                          <Link
                            to={`/author-profile/view-nft/${data.mintAddress}`}
                            state={{
                              id: publicKey.toString(),
                              viewOnly: listedMintAddress.includes(data?.mintAddress.toString()) ? false : true,
                              sellerData: seller,
                            }}
                          >
                            <span>
                              <img src={data?.uri} alt="" />
                            </span>
                          </Link>
                        </div>

                        <div className="collection-item-content">
                          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            {data?.name && data?.name?.length > 30 ?
                              <Marquee gradient={false}  >
                                <h5
                                  className="title"
                                  style={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                  }
                                  }>{data?.name}<span></span></h5>
                              </Marquee>
                              : <h5 className="title"> {data?.name}  </h5>}
                            <h5 className="title">
                              <span className="price" style={{ fontSize: '16px' }}>
                                {data?.price} {data?.currency?.symbol}
                              </span>
                            </h5>

                          </div>
                          <div style={{ justifyContent: 'flex-start' }}>
                            {data?.description && data?.description?.length > 30 ? <Marquee className="title" gradient={false} >
                              <h3 style={{
                                color: '#E039FD',
                                fontSize: '15px',
                                fontWeight: '200',
                                marginTop: '10px',
                                whiteSpace: 'nowrap',
                                // overflow: 'auto',
                              }}><span>{data?.description}</span></h3>
                            </Marquee> : <h3 style={{
                              color: '#E039FD',
                              fontSize: '14px',
                              fontWeight: '200',
                              marginTop: '10px',
                              whiteSpace: 'nowrap',
                              // overflow: 'auto',
                            }}><span>{data?.description}</span></h3>}
                          </div>
                        </div>
                        <div className="collection-item-bottom">
                          <ul>
                            {listedMintAddress.includes(data?.mintAddress.toString()) ?
                              data?.creators[0]?.address?.toString() !== publicKey.toString() ?
                                <li
                                  className="bid">
                                  <Link
                                    to={`/author-profile/view-nft/${data.mintAddress}`}
                                    state={{ id: publicKey.toString(), viewOnly: false, sellerData: seller }}
                                    className="btn"
                                  >
                                    {t('buy')}
                                  </Link>
                                </li> :
                                // ADD BUY BUTTON
                                <li
                                  className="bid">
                                  <Link
                                    to={`/author-profile/view-nft/${data.mintAddress}`}
                                    state={{ id: publicKey.toString(), viewOnly: true, sellerData: seller }}
                                    className="btn"
                                  >
                                    {t('view')}
                                  </Link> </li> :
                              <li
                                className="bid">
                                <Link
                                  to={`/author-profile/view-nft/${data.mintAddress}`}
                                  state={{ id: publicKey.toString(), viewOnly: true, sellerData: seller }}
                                  className="btn"
                                >
                                  {t('view')}
                                </Link>
                              </li>
                            }
                          </ul>
                        </div>
                      </div>

                    </div>
                  );
                })}
            </div>
            {/* <div className="row justify-content-center">
              <div className="col-xl-4 col-md-6 col-sm-6">
                <div className="top-collection-item">
                  <div className="collection-item-top">
                    <ul>
                      <li className="avatar"><a href="/author-profile" className="thumb"><img src="assets/img/others/top_col_avatar.png" alt="" /></a>By <a href="/author-profile" className="name">Jonson</a></li>
                      <li className="info-dots dropdown">
                        <span />
                        <span />
                        <span />
                        <a href="/#" className="dropdown-toggle" data-bs-toggle="dropdown" role="button" aria-expanded="false" />
                        <ul className="dropdown-menu">
                          <li><a href="/nft-marketplace">Artwork</a></li>
                          <li><a href="/nft-marketplace">Action</a></li>
                          <li><a href="/nft-marketplace">Author Action</a></li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <div className="collection-item-thumb">
                    <a href="/market-single"><img src="assets/img/others/top_collection01.jpg" alt="" /></a>
                  </div>
                  <div className="collection-item-content">
                    <h5 className="title"><a href="/market-single">Action Collection</a> <span className="price">5.4 ETH</span></h5>
                  </div>
                  <div className="collection-item-bottom">
                    <ul style={{ justifyContent: 'space-between' }}>
                      <li className="bid"><a href="/market-single" className="btn">place a bid</a></li>
                      <li className="wishlist"><a href="/login-register">59</a></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-md-6 col-sm-6">
                <div className="top-collection-item">
                  <div className="collection-item-top">
                    <ul>
                      <li className="avatar"><a href="/author-profile" className="thumb"><img src="assets/img/others/top_col_avatar.png" alt="" /></a>By <a href="/author-profile" className="name">Jonson</a></li>
                      <li className="info-dots dropdown">
                        <span />
                        <span />
                        <span />
                        <a href="/#" className="dropdown-toggle" data-bs-toggle="dropdown" role="button" aria-expanded="false" />
                        <ul className="dropdown-menu">
                          <li><a href="/nft-marketplace">Artwork</a></li>
                          <li><a href="/nft-marketplace">Action</a></li>
                          <li><a href="/nft-marketplace">Author Action</a></li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <div className="collection-item-thumb">
                    <a href="/market-single"><img src="assets/img/others/top_collection05.jpg" alt="" /></a>
                  </div>
                  <div className="collection-item-content">
                    <h5 className="title"><a href="/market-single">Pie Collection</a> <span className="price">5.4 ETH</span></h5>
                  </div>
                  <div className="collection-item-bottom">
                    <ul style={{ justifyContent: 'space-between' }}>
                      <li className="bid"><a href="/market-single" className="btn">place a bid</a></li>
                      <li className="wishlist"><a href="/#">59</a></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-md-6 col-sm-6">
                <div className="top-collection-item">
                  <div className="collection-item-top">
                    <ul>
                      <li className="avatar"><a href="/author-profile" className="thumb"><img src="assets/img/others/top_col_avatar.png" alt="" /></a>By <a href="/author-profile" className="name">Jonson</a></li>
                      <li className="info-dots dropdown">
                        <span />
                        <span />
                        <span />
                        <a href="/#" className="dropdown-toggle" data-bs-toggle="dropdown" role="button" aria-expanded="false" />
                        <ul className="dropdown-menu">
                          <li><a href="/nft-marketplace">Artwork</a></li>
                          <li><a href="/nft-marketplace">Action</a></li>
                          <li><a href="/nft-marketplace">Author Action</a></li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <div className="collection-item-thumb">
                    <a href="/market-single"><img src="assets/img/others/top_collection06.jpg" alt="" /></a>
                  </div>
                  <div className="collection-item-content">
                    <h5 className="title"><a href="/market-single">Artwork Collection</a> <span className="price">5.4 ETH</span></h5>
                  </div>
                  <div className="collection-item-bottom">
                    <ul style={{ justifyContent: 'space-between' }}>
                      <li className="bid"><a href="/market-single" className="btn">place a bid</a></li>
                      <li className="wishlist"><a href="/#">59</a></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-md-6 col-sm-6">
                <div className="top-collection-item">
                  <div className="collection-item-top">
                    <ul>
                      <li className="avatar"><a href="/author-profile" className="thumb"><img src="assets/img/others/top_col_avatar.png" alt="" /></a>By <a href="/author-profile" className="name">Jonson</a></li>
                      <li className="info-dots dropdown">
                        <span />
                        <span />
                        <span />
                        <a href="/#" className="dropdown-toggle" data-bs-toggle="dropdown" role="button" aria-expanded="false" />
                        <ul className="dropdown-menu">
                          <li><a href="/nft-marketplace">Artwork</a></li>
                          <li><a href="/nft-marketplace">Action</a></li>
                          <li><a href="/nft-marketplace">Author Action</a></li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <div className="collection-item-thumb">
                    <a href="/market-single"><img src="assets/img/others/top_collection07.jpg" alt="" /></a>
                  </div>
                  <div className="collection-item-content">
                    <h5 className="title"><a href="/market-single">Action Collection</a> <span className="price">5.4 ETH</span></h5>
                  </div>
                  <div className="collection-item-bottom">
                    <ul style={{ justifyContent: 'space-between' }}>
                      <li className="bid"><a href="/market-single" className="btn">place a bid</a></li>
                      <li className="wishlist"><a href="/#">59</a></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-md-6 col-sm-6">
                <div className="top-collection-item">
                  <div className="collection-item-top">
                    <ul>
                      <li className="avatar"><a href="/author-profile" className="thumb"><img src="assets/img/others/top_col_avatar.png" alt="" /></a>By <a href="/author-profile" className="name">Jonson</a></li>
                      <li className="info-dots dropdown">
                        <span />
                        <span />
                        <span />
                        <a href="/#" className="dropdown-toggle" data-bs-toggle="dropdown" role="button" aria-expanded="false" />
                        <ul className="dropdown-menu">
                          <li><a href="/nft-marketplace">Artwork</a></li>
                          <li><a href="/nft-marketplace">Action</a></li>
                          <li><a href="/nft-marketplace">Author Action</a></li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <div className="collection-item-thumb">
                    <a href="/market-single"><img src="assets/img/others/top_collection08.jpg" alt="" /></a>
                  </div>
                  <div className="collection-item-content">
                    <h5 className="title"><a href="/market-single">Artwork Collection</a> <span className="price">5.4 ETH</span></h5>
                  </div>
                  <div className="collection-item-bottom">
                    <ul style={{ justifyContent: 'space-between' }}>
                      <li className="bid"><a href="/market-single" className="btn">place a bid</a></li>
                      <li className="wishlist"><a href="/#">59</a></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-md-6 col-sm-6">
                <div className="top-collection-item">
                  <div className="collection-item-top">
                    <ul>
                      <li className="avatar"><a href="/author-profile" className="thumb"><img src="assets/img/others/top_col_avatar.png" alt="" /></a>By <a href="/author-profile" className="name">Jonson</a></li>
                      <li className="info-dots dropdown">
                        <span />
                        <span />
                        <span />
                        <a href="/#" className="dropdown-toggle" data-bs-toggle="dropdown" role="button" aria-expanded="false" />
                        <ul className="dropdown-menu">
                          <li><a href="/nft-marketplace">Artwork</a></li>
                          <li><a href="/nft-marketplace">Action</a></li>
                          <li><a href="/nft-marketplace">Author Action</a></li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <div className="collection-item-thumb">
                    <a href="/market-single"><img src="assets/img/others/top_collection09.jpg" alt="" /></a>
                  </div>
                  <div className="collection-item-content">
                    <h5 className="title"><a href="/market-single">NFT Collection</a> <span className="price">5.4 ETH</span></h5>
                  </div>
                  <div className="collection-item-bottom">
                    <ul style={{ justifyContent: 'space-between' }}>
                      <li className="bid"><a href="/market-single" className="btn">place a bid</a></li>
                      <li className="wishlist"><a href="/#">59</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Authors