import { LAMPORTS_PER_SOL } from "@solana/web3.js";
const listingByAddress = (data: any) => {
  //console.log(data);
  return {
    mintAddress: data?.asset?.mint?.address,
    tradeStateAddress: data?.tradeStateAddress,
    creators: data?.asset?.json?.properties?.creators,
    name: data?.asset?.json?.name,
    attributes: data?.asset?.json?.attributes,
    image: data?.asset?.json?.image,
    description: data?.asset?.json?.description,
    sellerFeeBasisPoints: data?.asset?.sellerFeeBasisPoints / 100,
    symbol: data?.asset?.json?.symbol,
    primarySaleHappened: data?.asset?.json?.primarySaleHappened,
    createdAt: data?.createdAt,
    price: data?.price?.basisPoints?.toNumber() / LAMPORTS_PER_SOL,
    currency: data?.price?.currency,
    sellerAddress: data?.sellerAddress?.toString(),
    collection: data?.asset?.collection?.address?.toString(),
  };
};

export { listingByAddress };
