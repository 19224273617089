import React, { useRef, useState } from 'react'
import Header from '../Components/Common/Header/Header';
import Footer from '../Components/Common/Footer/Footer';
import { Button } from '../Components/Common/WalletAdapterUi/Button';
import $ from 'jquery';
import { ToastContainer, toast, cssTransition } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export default function PhantomWallet() {
    const section1 = useRef(null)
    const section2 = useRef(null)
    const section3 = useRef(null)
    const section4 = useRef(null)
    const [formValues, setformValues] = useState({
        author: "",

        mail: "",

        textarea: "",
    });
    const [formErrors, setFormErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setformValues({ ...formValues, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        let errorObj = validate(formValues);
        if (Object.keys(errorObj).length == 0) {
            console.log(formValues); //submit
            toast.success(<div className="text">Successfully send the message</div>, { autoClose: 6000 },
                {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,

                })
            setformValues({

                mail: "",
                author: "",
                textarea: ""
            }
            );
            setFormErrors({})
        } else {
            console.log(errorObj)
            setFormErrors(errorObj)
            Object.keys(errorObj).map(function (key, index) {
                $(`#${key}`).addClass('shakeEffect')
                $(`#${key}`).on('webkitAnimationEnd oanimationend msAnimationEnd animationend', function (e) {
                    $(`#${key}`).delay(200).removeClass('shakeEffect');

                });
            })
        }

    }



    const validate = (values) => {
        const errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        // const regex1 = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i;
        if (!values.author) {
            errors.author = "Author is required! ";
        }

        if (!values.mail) {
            errors.mail = "Email is required! ";
        } else if (!regex.test(values.mail)) {
            errors.mail = "This is not valid email format! ";
        }


        if (!values.textarea) {
            errors.textarea = "Message is required! "
        }
        return errors;
    };






    return (
        <>
            <Header />
            <br /><br /><br /><br />

            <section className="blog-details-area pt-80 pb-80">


                <div className='btn-header-1'>
                    <button className='grid-item intro' id="btn" onClick={() => section1.current.scrollIntoView()} ><p className='intro-blog-p'><button className='btn-item2'>1</button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Introduction&nbsp;&nbsp;&nbsp;&nbsp;</p></button>
                    <button className='grid-item intro' id="btn" onClick={() => section2.current.scrollIntoView()} ><p className='intro-blog-p'><button className='btn-item2'>2</button>&nbsp;&nbsp;Types of Wallet&nbsp;</p></button>
                    <button className='grid-item intro' id="btn" onClick={(() => section3.current.scrollIntoView())} ><p className='intro-blog-p'><button className='btn-item2'>3</button>&nbsp;&nbsp;&nbsp;&nbsp;Setting Up&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p></button>
                    <button className='grid-item intro' id="btn" onClick={(() => section4.current.scrollIntoView())} ><p className='intro-blog-p'><button className='btn-item2'>4</button>&nbsp;&nbsp;&nbsp;Burner Wallet&nbsp;&nbsp;&nbsp;</p></button>

                </div>

                <div className="container">
                    <div className="row">
                        <div className="col-xxl-9 col-lg-8">
                            <br />
                            <div className="standard-blog-item blog-details-content">
                                <div className="blog-thumb">
                                    <img ref={section1} src="assets/img/blog/phantomwallet-1.png" alt="" />
                                </div>
                                <div className="standard-blog-content">
                                    <ul className="standard-blog-meta">
                                        <li><h6><a><i className="flaticon-supermarket" />NFT Marketplace</a></h6></li>
                                        <li><h6><a><i className="flaticon-avatar" />Admin</a></h6></li>
                                        <li><h6><a><i className="flaticon-calendar" />Feb 22, 2022</a></h6></li>
                                    </ul>
                                    <h4 className="title">Introduction to Phantom Wallet</h4>
                                    <p>
                                        Banks handle, store, and keep track of your financial
                                        assets, as we all know. A wallet is like a bank except you
                                        are the bank.You’re in charge of your funds.
                                        <br /><br />
                                        There is a lot of responsibility that comes with it,
                                        but don't worry—we'll show you how to be safe.
                                        In the Web3 world, a wallet is also a means of identification.
                                        To know more about Web3 space <a href="https://en.wikipedia.org/wiki/Web3#:~:text=Web3%20(also%20known%20as%20Web,%2C%20and%20token%2Dbased%20economics.">wikipedia</a>.
                                        <br /><br />
                                        Whatever you do in this space will involve your wallet. It’s your passport to all things web3.
                                        Your wallet is your access point to everything to do with NFTs: it is where you exchange tokens,
                                        purchase NFTs, and store these assets.

                                    </p>
                                    <blockquote>
                                        “ Web3 is the internet owned by the builders and users, orchestrated with tokens.“
                                        <footer>Chris Dixon ~American Internet Entrepreneur</footer>
                                    </blockquote>
                                    <img id='nft-img' ref={section2} src="assets/img/blog/type-1.png" alt="" />
                                    <br /><br />

                                    <h4 className="title">What types of wallets are out there?</h4>
                                    <p>
                                        There are many different types of Solana wallets.
                                        On NFT-PLUS, you can also connect to a Solfare wallet.
                                        For beginners, we recommend <a href='https://phantom.app/'> Phantom wallet. </a>  These wallets are typically available as a
                                        browser extension and/or phone app, which you can easily connect to NFT-PLUS. With <a href='https://www.fool.com/investing/stock-market/market-sectors/financials/cryptocurrency-stocks/solana/#:~:text=Solana%20(CRYPTO%3ASOL)%20is,largest%20cryptocurrencies%20in%20the%20world.'> $SOL </a> in
                                        your wallet, you’ll be able to buy NFTs.</p>
                                    <br /><br />
                                    <img id='nft-img' ref={section3} src="assets/img/blog/setting-1.png" alt="" />

                                    <br /><br />

                                    <h4 className="title">How do I set up a Phantom wallet?</h4>
                                    <br /><br />
                                    <div className='grid-container' id='nft-19'><div className='flex-container-1'>
                                        <div id='crypto'><button id='bullet-1'>1</button>&nbsp;&nbsp;

                                            <b> Download the extension:&nbsp;&nbsp;</b>
                                            <br /><br />
                                            To download the <a href='https://phantom.app/'> Phantom wallet </a> extension,
                                            you need to go to the Phantom website or
                                            can directly go to the <a href='https://chrome.google.com/webstore/detail/phantom/bfnaelmomeimhlpmgjnjophhpkkoljpa'> Google Chrome
                                                extension store </a> or <a href='https://addons.mozilla.org/en-US/firefox/addon/phantom-app/'> Firefox extension </a>.
                                            <br /><br />
                                            <img id='nft-img-1' src="assets/img/blog/01.png" alt="" />


                                            <br /><br />
                                            Click on the Add to Chrome/ Add to Firefox button whether you are in the Phantom website or
                                            Chrome extension store/ Firefox extension store.
                                            <br /><br />
                                            <img id='nft-img-1' src="assets/img/blog/02.png" alt="" />
                                            <br /><br />
                                            <center><b id='or'>OR</b></center>
                                            <img id='nft-img-1' src="assets/img/blog/10.png" alt="" />

                                            <br /><br />
                                        </div>
                                        <br />
                                        <div id='crypto'><button id='bullet-1'>2</button>&nbsp;&nbsp;<b>Click on Phantom Wallet extension:&nbsp;&nbsp;
                                            <br /><br />
                                        </b>
                                            Now you’ve successfully added the Phantom Wallet Extension to your browser.
                                            <br /><br />
                                            On the successful installation, the Phantom wallet window will automatically
                                            open in the new tab. If not, you can click the <b> puzzle icon </b> on the top
                                            right of your browser and click on the <b> Phantom Wallet.</b>
                                            <br /><br />

                                            <img id='nft-img-1' src="assets/img/blog/09.png" alt="" />
                                            <br /><br />
                                            For the faster access in the subsequent times, you can use ‘pin icon’ to
                                            pin the Phantom Wallet extension.In Firefox, click the Phantom Wallet Icon on the top right in your browser window.
                                            <br /><br />

                                            <center><b id='or'>OR</b></center>
                                            <br /><br />
                                            In Firefox, click the Phantom Wallet Icon on the top right in your browser window.
                                            <br /><br />
                                            <img id='nft-img-1' src="assets/img/blog/08.png" alt="" />


                                        </div>

                                        <br />
                                        <div id='crypto'><button id='bullet-1'>3</button>&nbsp;&nbsp;
                                            <b>Click on Create New Wallet: &nbsp;&nbsp;
                                            </b>
                                            <br /><br />
                                            <ol>
                                                <li>1. Create New Wallet</li>

                                                <li>2. Use Secret Recovery Phrase</li>
                                            </ol>
                                            <br /><br />
                                            Since you are a new user, click on <b> Create New Wallet </b>
                                            <br /><br />
                                            <img id='nft-img-1' src="assets/img/blog/07.png" alt="" />

                                            <br /><br />


                                        </div>
                                        <br /><br />
                                        <div id='crypto'><button id='bullet-1'>4</button>&nbsp;&nbsp;
                                            <b>Storing Secret Recovery Phrase or Mnemonic: &nbsp;&nbsp;
                                            </b>
                                            <br /><br />
                                            After clicking the Create New Wallet, you could see the Secret Recovery Phrase aka Mnemonic.
                                            <br /><br />
                                            Kindly note it somewhere private. You could only recover your account via Secret Phrase. There is no other alternative to this. If you lost your Recovery Phrase, you lost everything.
                                            <br /><br />
                                            You can copy the code by clicking the <b> Copy button </b> underneath the Secret Recovery Phrase.
                                            <br /><br />
                                            <img id='nft-img-1' src="assets/img/blog/07.png" alt="" />
                                            <br /><br />
                                            You can store your Secret Recovery Phrase in a hardware wallet, a mobile wallet, or a desktop wallet. Otherwise, you can write
                                            it down on a piece of paper and keep it somewhere private.


                                        </div>
                                        <br /><br />
                                        <div id='crypto'><button id='bullet-1'>5</button>&nbsp;&nbsp;
                                            <b>Create a password for your wallet:</b>&nbsp;&nbsp;
                                            <br /><br />
                                            The next step will be creating a password for your Phantom Wallet.
                                            <br /><br />
                                            You can type your password in the <b> Password </b>
                                            field and <b> Confirm Password. </b> Ensure to set
                                            a solid password with combinations of numbers,
                                            alphabets, and special characters.
                                            <br /><br />

                                            <img id='nft-img-1' src="assets/img/blog/05.png" alt="" />
                                            <br /><br />
                                            After typing the password, you have to select the check box and Click Save; make sure to read the Terms of Service before selecting.
                                            In a nutshell, Terms of Service include a privacy policy, third-party services, content ownership and removal, rights, fees, account
                                            termination policy, and government law.
                                        </div>
                                        <br /><br />
                                        <div id='crypto'><button id='bullet-1'>6</button>&nbsp;&nbsp;
                                            <b> Keyboard shortcut:&nbsp;&nbsp;</b>
                                            <br /><br />
                                            After setting the password, now your browser window will show an instructional message for ease of use.
                                            <br /><br />
                                            That is nothing but a shortcut to opening the Phantom Wallet. Click Alt/ Option+Shift+P together to open the Phantom Wallet extension without navigating in your browser.
                                            <br /><br />
                                            Here, you need to click the <b> Continue </b> button.
                                            <br /><br />
                                            <img id='nft-img-1' src="assets/img/blog/04.png" alt="" />

                                            <br /><br />
                                            This is one of the cool features to use.

                                        </div>
                                        <br /><br />
                                        <div id='crypto'><button id='bullet-1'>7</button>&nbsp;&nbsp;
                                            <b>Use Phantom:&nbsp;&nbsp;</b>
                                            <br /><br />
                                            On the successful completion of all steps mentioned here, you could see the message below.
                                            Click <b>Finish.</b>
                                            <br /><br />

                                            <img id='nft-img-1' src="assets/img/blog/03.png" alt="" />
                                        </div>
                                    </div>

                                    </div>
                                    <br /><br />
                                    <img id='nft-img' ref={section4} src="assets/img/blog/burner-1.png" alt="" />
                                    <br /><br />
                                    <h4 className="title">How to use a burner wallet?</h4>
                                    <br />
                                    <p>
                                        Some sites can be shady and will attempt to steal your funds when you connect your wallet
                                        to them. By using a burner wallet, you’re limiting your risk of exposure.
                                        <br /><br />
                                        <b>Steps on using a burner wallet: </b>
                                    </p>

                                    <br />
                                    <div className='grid-container' id='nft-19'><div className='flex-container-1'>
                                        <div id='crypto'><button id='bullet-1'>1</button>&nbsp;&nbsp;
                                            Within a single crypto wallet, you can have many different wallets that
                                            use the same recovery phrase/private key. When minting NFTs, it is best
                                            practice to use a burner wallet, which is a temporary wallet used only
                                            to mint. By using a burner wallet, you’re limiting your risk of exposure
                                            to shady websites that attempt to steal your funds when your wallet is
                                            connected to them.



                                        </div>
                                        <br /><br />
                                        <div id='crypto'><button id='bullet-1'>2</button>&nbsp;&nbsp;
                                            Once you’ve created your burner wallet within your main wallet, pre-fund your burner
                                            wallet with the exact amount of $SOL
                                            you require for minting.
                                            After minting, transfer your newly minted NFTs to your main wallet.
                                        </div>


                                    </div></div>
                                    <br /><br />
                                    <div className='grid-container' id='nft-19'><div className='flex-container-2'>
                                        <center><img id='img-small' src='assets/img/blog/hazard.png' /><p><b>Safety Note:</b></p></center>

                                        <br /><br />
                                        <div id='crypto'><button id='bullet-1'>1</button>&nbsp;&nbsp;
                                            <b>Check the URL:&nbsp;</b>
                                            <p>
                                                Always check the URL of any sites you visit to make sure it is the official link.
                                                Bookmark the sites that you regularly visit. Phishing websites pose as the real
                                                website but are identical fake sites attempting to steal your private information.
                                            </p>
                                        </div>
                                        <br /><br />
                                        <div id='crypto'><button id='bullet-1'>2</button>&nbsp;&nbsp;
                                            <b>Get a hardware wallet:&nbsp;</b>
                                            <p>
                                                Consider getting a hardware wallet for an increased level of security.
                                                A hardware wallet can keep your cryptocurrency funds safe even if
                                                the computer you’re using isn’t secure.  Hardware wallets protect
                                                your private keys and they let you sign and confirm transactions on the
                                                Solana blockchain.
                                            </p>
                                        </div>
                                        <br /><br />
                                        <div id='crypto'><button id='bullet-1'>3</button>&nbsp;&nbsp;
                                            <b>Signing Up Wallet:&nbsp;</b>
                                            <p>
                                                When signing up for a new wallet, you’ll be given 12 words known as your
                                                “Secret Recovery Phrase”. This is your private key and you can think of
                                                it as your password. These words are what you will use to recover your
                                                wallet if you need to log into a new device.
                                            </p>
                                        </div>
                                        <br /><br />
                                        <div id='crypto'><button id='bullet-1'>4</button>&nbsp;&nbsp;
                                            <b>DYOR Search:&nbsp;</b>
                                            <p>
                                                NFTs are a whole lot of fun but you should be aware that they can
                                                fluctuate in value and are a highly volatile asset class. Please do
                                                your own research (DYOR) before you decide to invest in NFTs.
                                            </p>
                                        </div>

                                    </div>

                                    </div>


                                    <div className="blog-line" />
                                    <div className="blog-details-bottom">
                                        <div className="blog-details-tags">
                                            <ul>
                                                <li className="title" >Tags :</li>
                                                <li><a><h6>Business,</h6></a></li>
                                                <li><a><h6>Work,</h6></a></li>
                                                <li><a><h6>Knowledge,</h6></a></li>
                                                <li><a><h6>Online</h6></a></li>
                                            </ul>
                                        </div>
                                        <div className="blog-details-social">
                                            <ul>
                                                <li>
                                                    <a href="https://www.instagram.com/nft_plus.me/" target="_blank" rel="noreferrer">

                                                        <i className="fab fa-instagram" />
                                                    </a>
                                                </li>

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br /><br />
                            {/* <div className="blog-next-prev">
                                <ul>
                                    <li className="blog-prev">
                                        <Button id='post-button'> <img src="assets/img/icons/left_arrow.png" alt="img" />Previous Post</Button>
                                    </li>
                                    <li className="blog-next">
                                        <Button id='post-button'>Next Post<img src="assets/img/icons/right_arrow.png" alt="img" /></Button>
                                    </li>
                                </ul>
                            </div> */}
                            {/* <div className="comment-reply-box">
                                <h5 className="title">Leave a Reply</h5>
                                <form onSubmit={handleSubmit} className="comment-reply-form">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-grp">
                                                <input type="text"
                                                    name="author"
                                                    placeholder="Author"

                                                    value={formValues.author}
                                                    onChange={handleChange}
                                                />


                                                <p id='author' className="errors_message">{formErrors.author}</p>


                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-grp">
                                                <input type="text" placeholder="Your Email *"
                                                    placeholder="Email"
                                                    name="mail"
                                                    value={formValues.mail}
                                                    onChange={handleChange}
                                                />
                                                <p id='mail' className="errors_message">{formErrors.mail}</p> </div>
                                        </div>
                                    </div>
                                    <div className="form-grp">
                                        <textarea name="message" placeholder="Type Comment Here..." defaultValue={""}
                                            name="textarea"
                                            value={formValues.textarea}
                                            onChange={handleChange}

                                        />
                                        <p id="textarea" className="errors_message">{formErrors.textarea}</p>

                                    </div>
                                    <button type="submit" className="btn">Submit now</button>
                                </form>
                            </div> */}
                        </div>
                        {/* <div className="col-xxl-3 col-lg-4">
                            <aside className="blog-sidebar">
                                <div className="widget">
                                    <h4 className="sidebar-title">Search</h4>
                                    <div className="sidebar-search">
                                        <form action="#">
                                            <input type="text" placeholder="Search ..." />
                                            <button type="submit"><i className="fa fa-search" /></button>
                                        </form>
                                    </div>
                                </div>
                                <div className="widget">
                                    <h4 className="sidebar-title">Categories</h4>
                                    <div className="sidebar-cat-list">
                                        <ul>
                                            <li><a href="/#">Domain Names <i className="fas fa-angle-double-right" /></a></li>
                                            <li><a href="/#">Photography <i className="fas fa-angle-double-right" /></a></li>
                                            <li><a href="/#">Coinbase <i className="fas fa-angle-double-right" /></a></li>
                                            <li><a href="/#">Trading Cards <i className="fas fa-angle-double-right" /></a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="widget">
                                    <h4 className="sidebar-title">Recent Post</h4>
                                    <div className="rc-post-list">
                                        <ul>
                                            <li>
                                                <div className="rc-post-thumb">
                                                    <a href="/blog-details"><img src="assets/img/blog/rc_post_thumb01.jpg" alt="" /></a>
                                                </div>
                                                <div className="rc-post-content">
                                                    <ul className="standard-blog-meta">
                                                        <li><a href="/#"><i className="flaticon-avatar" />Admin</a></li>
                                                        <li><a href="/#"><i className="flaticon-calendar" />Mar 10, 2022</a></li>
                                                    </ul>
                                                    <h5 className="title"><a href="/blog-details">Marketplace is the online shop for the video</a></h5>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="rc-post-thumb">
                                                    <a href="/blog-details"><img src="assets/img/blog/rc_post_thumb02.jpg" alt="" /></a>
                                                </div>
                                                <div className="rc-post-content">
                                                    <ul className="standard-blog-meta">
                                                        <li><a href="/#"><i className="flaticon-avatar" />Admin</a></li>
                                                        <li><a href="/#"><i className="flaticon-calendar" />Mar 10, 2022</a></li>
                                                    </ul>
                                                    <h5 className="title"><a href="/blog-details">Marketplace is the online shop for the video</a></h5>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </aside>
                        </div> */}
                    </div>
                </div>
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover

                />
            </section>
            <Footer />

        </>
    )
}
