import React from "react";
import NftMarket from "../Components/Common/Explore/NftMarket";

import Layout from "../Components/Common/Layout/MainLayout";

const Explore = () => {
  return (
    <>
      <Layout>
        <NftMarket />
      </Layout>
    </>
  );
};

export default Explore;
