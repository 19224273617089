import React, { useRef, useState } from 'react'
import Header from '../Components/Common/Header/Header';
import Footer from '../Components/Common/Footer/Footer';
import { Button } from '../Components/Common/WalletAdapterUi/Button';
import $ from 'jquery';
import { ToastContainer, toast, cssTransition } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';


export default function Metaverse() {
    const { t } = useTranslation();

    const section1 = useRef(null)
    const section2 = useRef(null)
    const section3 = useRef(null)
    const section4 = useRef(null)
    const section5 = useRef(null)
    const [formValues, setformValues] = useState({
        author: "",

        mail: "",

        textarea: "",
    });
    const [formErrors, setFormErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setformValues({ ...formValues, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        let errorObj = validate(formValues);
        if (Object.keys(errorObj).length == 0) {
            console.log(formValues); //submit
            toast.success(<div className="text">Successfully send the message</div>, { autoClose: 6000 },
                {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,

                })
            setformValues({

                mail: "",
                author: "",
                textarea: ""
            }
            );
            setFormErrors({})
        } else {
            console.log(errorObj)
            setFormErrors(errorObj)
            Object.keys(errorObj).map(function (key, index) {
                $(`#${key}`).addClass('shakeEffect')
                $(`#${key}`).on('webkitAnimationEnd oanimationend msAnimationEnd animationend', function (e) {
                    $(`#${key}`).delay(200).removeClass('shakeEffect');

                });
            })
        }

    }



    const validate = (values) => {
        const errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        // const regex1 = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i;
        if (!values.author) {
            errors.author = "Author is required! ";
        }

        if (!values.mail) {
            errors.mail = "Email is required! ";
        } else if (!regex.test(values.mail)) {
            errors.mail = "This is not valid email format! ";
        }


        if (!values.textarea) {
            errors.textarea = "Message is required! "
        }
        return errors;
    };






    return (
        <>
            <Header />
            <br /><br /><br /><br />

            <section className="blog-details-area pt-80 pb-80">


                <div className='btn-header-1'>
                    <button className='grid-item intro' id="btn" onClick={() => section1.current.scrollIntoView()} ><p className='intro-blog-p'><button className='btn-item2'>1</button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Introduction&nbsp;&nbsp;&nbsp;&nbsp;</p></button>
                    <button className='grid-item intro' id="btn" onClick={() => section2.current.scrollIntoView()} ><p className='intro-blog-p'><button className='btn-item2'>2</button>&nbsp;&nbsp;&nbsp;Video Games&nbsp;&nbsp;&nbsp;</p></button>
                    <button className='grid-item intro' id="btn" onClick={(() => section3.current.scrollIntoView())} ><p className='intro-blog-p'><button className='btn-item2'>3</button>&nbsp;&nbsp;&nbsp;&nbsp;Crypto Link&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p></button>
                    <button className='grid-item intro' id="btn" onClick={(() => section4.current.scrollIntoView())} ><p className='intro-blog-p'><button className='btn-item2'>4</button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Usage  &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p></button>
                    <button className='grid-item intro' id="btn" onClick={(() => section5.current.scrollIntoView())} ><p className='intro-blog-p'><button className='btn-item2'>5</button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Example&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p></button>

                </div>

                <div className="container">
                    <div className="row">
                        <div className="col-xxl-9 col-lg-8">
                            <br />
                            <div className="standard-blog-item blog-details-content">
                                <div className="blog-thumb">
                                    <img ref={section1} src="assets/img/blog/metaverse-2.png" alt="" />
                                </div>
                                <div className="standard-blog-content">
                                    <ul className="standard-blog-meta">
                                        <li><h6><a><i className="flaticon-supermarket" />NFT Marketplace</a></h6></li>
                                        <li><h6><a><i className="flaticon-avatar" />{t('admin')}</a></h6></li>
                                        <li><h6><a><i className="flaticon-calendar" />Aug 20, 2022</a></h6></li>
                                    </ul>
                                    <h4 className="title">Introduction to Metaverse</h4>
                                    <p>
                                        The metaverse is a concept of a persistent, online,
                                        3D universe that combines multiple different virtual spaces.
                                        You can think of it as a future iteration of the internet.
                                        The metaverse will allow users to work, meet, game, and socialize
                                        together in these 3D spaces.
                                        <br /><br />
                                        The connections between the financial, virtual, and
                                        physical worlds have become increasingly linked. The
                                        devices we use to manage our lives give us access to
                                        almost anything we want at the touch of a button. The
                                        crypto ecosystem hasn't escaped this either. NFTs,
                                        blockchain games, and crypto payments aren't just limited
                                        to crypto geeks anymore. They're now all easily available
                                        as part of a developing metaverse.
                                        <br /><br />


                                    </p>
                                    <blockquote>
                                        "This Metaverse is going to be far more pervasive and powerful
                                        than anything else. If one central company gains control of this,
                                        they will become more powerful than any government and be a god of Earth"
                                        <footer>Tim Sweeney ~CEO of Epic Games</footer>
                                    </blockquote>
                                    <img id='nft-img' ref={section2} src="assets/img/blog/videogame-1.png" alt="" />
                                    <br /><br />

                                    <h4 className="title">Why are video games linked to the metaverse?</h4>
                                    <p>
                                        Because of the emphasis on 3D virtual reality, video games offer the closest metaverse experience currently.
                                        This point isn’t just because they are 3D, though. Video games now offer services and features that cross over into other aspects of our
                                        lives. The video game Roblox even hosts virtual events like concerts and meetups. Players don’t just play the game anymore; they also
                                        use it for other activities and parts of their lives in "cyberspace". For example, in the multiplayer game Fortnite, 12.3 million players
                                        took part in Travis Scott’s virtual in-game music tour.
                                    </p>
                                    <br /><br />
                                    <img id='nft-img' ref={section3} src="assets/img/blog/cryptoin-1.png" alt="" />

                                    <br /><br />

                                    <h4 className="title">How does crypto fit into the metaverse?</h4>
                                    <br /><br />
                                    <p>
                                        Gaming provides the 3D aspect of the metaverse but doesn’t
                                        cover everything needed in a virtual world that can cover all
                                        aspects of life. Crypto can offer the other key parts required,
                                        such as digital proof of ownership, transfer of value,
                                        governance, and accessibility.
                                        <br /><br />
                                        If, in the future, we work, socialize, and even purchase
                                        virtual items in the metaverse, we need a secure way of
                                        showing ownership. We also need to feel safe transferring
                                        these items and money around the metaverse. Finally,
                                        we will also want to play a role in the
                                        decision-making taking place in the metaverse if
                                        it will be such a large part of our lives.
                                        <br /><br />
                                        Some video games contain some basic solutions already, but many developers
                                        use
                                        crypto and blockchain instead as a better option.
                                        Blockchain provides a decentralized and transparent way of
                                        dealing with the topics, while video-game development is more centralized.
                                        Blockchain developers also take influence from the video game world too. Gamification is common in
                                        Decentralized Finance (DeFi) and GameFi. It seems there will be enough similarities in the future that the two
                                        worlds may become even more integrated.
                                        <br /><br />
                                        <b>The key aspects of blockchain suited to the metaverse are: </b>
                                    </p>
                                    <div className='grid-container' id='nft-19'><div className='flex-container-1'>
                                        <div id='crypto'><button id='bullet-1'>1</button>&nbsp;&nbsp;

                                            <b> Digital proof of ownership:&nbsp;&nbsp;</b>
                                            <br /><br />

                                            <img id='nft-img-1' src="assets/img/blog/proofofownership-1.png" alt="" />
                                            <br /><br />
                                            By owning a wallet with access to your private keys, you can instantly prove
                                            ownership of activity or an asset on the blockchain. For example, you could
                                            show an exact transcript of your transactions on the blockchain while at
                                            work to show accountability. A wallet is one of the most secure and robust
                                            methods for establishing a digital identity and proof of ownership.
                                        </div>
                                        <br />
                                        <div id='crypto'><button id='bullet-1'>2</button>&nbsp;&nbsp;<b>Digital collectibility:&nbsp;&nbsp;
                                            <br /><br />
                                        </b>
                                            <img id='nft-img-1' src="assets/img/blog/digitalcollectibility-1.png" alt="" />

                                            <br /><br />
                                            Just as we can establish who owns something, we can also show that an item is original and unique. For a metaverse looking to incorporate more real-life activities,
                                            this is important. Through NFTs, we can create objects that
                                            are 100% unique and can never be copied exactly or forged.
                                            A blockchain can also represent ownership of physical items.


                                        </div>

                                        <br />
                                        <div id='crypto'><button id='bullet-1'>3</button>&nbsp;&nbsp;
                                            <b>Transfer of value: &nbsp;&nbsp;
                                            </b>
                                            <br /><br />
                                            <img id='nft-img-1' src="assets/img/blog/transferofvalue-1.png" alt="" />

                                            <br /><br />
                                            A metaverse will need a way to transfer value securely that users trust. In-game currencies in multiplayer games are
                                            less secure than crypto
                                            on a blockchain. If users spend large amounts of time in the metaverse and even earn money
                                            there, they will need a reliable currency.

                                        </div>
                                        <br /><br />
                                        <div id='crypto'><button id='bullet-1'>4</button>&nbsp;&nbsp;
                                            <b>Governance: &nbsp;&nbsp;
                                            </b>
                                            <br /><br />
                                            <img id='nft-img-1' src="assets/img/blog/governance-1.png" alt="" />

                                            <br /><br />
                                            The ability to control the rules of your interaction with the
                                            metaverse should also be important for users. In real life, we
                                            can have voting rights in companies and elect leaders and governments.
                                            The metaverse will also need ways to implement fair governance,
                                            and blockchain is already a proven way of doing this.
                                            <br /><br />
                                        </div>
                                        <br /><br />
                                        <div id='crypto'><button id='bullet-1'>5</button>&nbsp;&nbsp;
                                            <b>Accessibility:</b>&nbsp;&nbsp;
                                            <br /><br />
                                            <img id='nft-img-1' src="assets/img/blog/accessibility-1.png" alt="" />
                                            <br /><br />
                                            Creating a wallet is open to anyone around the world on public blockchains. Unlike a bank account, you don’t need to
                                            pay any money or provide any details. This makes it one of the most accessible ways to manage
                                            finances and an online, digital identity.

                                        </div>
                                        <br /><br />
                                        <div id='crypto'><button id='bullet-1'>6</button>&nbsp;&nbsp;
                                            <b> Interoperability:&nbsp;&nbsp;</b>
                                            <br /><br />
                                            <img id='nft-img-1' src="assets/img/blog/introperability-1.png" alt="" />

                                            <br /><br />
                                            Blockchain technology is continuously improving compatibility between different platforms. Projects like Polkadot (DOT) and
                                            Avalanche (AVAX) allow for creating custom blockchains that can interact with each other.
                                            A single metaverse will need to connect multiple projects, and blockchain technology already has solutions for this.
                                        </div>
                                        <br /><br />

                                    </div>

                                    </div>
                                    <br /><br />
                                    <img id='nft-img' ref={section4} src="assets/img/blog/workingofmeta.png" alt="" />

                                    <br /><br />
                                    <h4 className="title">What is a metaverse job?</h4>
                                    <br />
                                    <p>
                                        As we mentioned, the metaverse will combine all aspects of life in one place. While many
                                        people already work at home, in the metaverse, you will
                                        be able to enter a 3D office and interact with your colleagues’
                                        avatars. Your job may also be metaverse related and provide you
                                        with income directly usable in the metaverse. In fact, these kinds
                                        of jobs already exist in a similar form.
                                        <br /><br />
                                        GameFi and play-to-earn models now provide steady income streams for people worldwide.
                                        These online jobs are great candidates for metaverse implementation in
                                        the future, as they show that people are willing to spend their time
                                        living and earning in virtual worlds. Play-to-earn games like Axie
                                        Infinity and Gods Unchained don’t even have 3D worlds or avatars.
                                        However, it’s the principle that they could be part of the metaverse
                                        as a way to earn money entirely in the online world.
                                    </p>
                                    <br /><br />


                                    <img id='nft-img' ref={section5} src="assets/img/blog/example1-1.png" alt="" />
                                    <br /><br />
                                    <h4 className="title">Metaverse Example</h4>
                                    <br /><br />
                                    <p>
                                        While we don’t yet have a single, linked metaverse, we have plenty of
                                        platforms and projects similar to the metaverse.
                                        Typically, these also incorporate NFTs and other blockchain elements.
                                        <br /><br />
                                        <b>Let’s look at three examples:</b>

                                    </p>
                                    <br /><br />
                                    <div className='grid-container' id='nft-19'><div className='flex-container-1'>
                                        <div id='crypto'><button id='bullet-1'>1</button>&nbsp;&nbsp;

                                            <b> SecondLive:&nbsp;&nbsp;</b>
                                            <br /><br />

                                            <img id='nft-img-1' src="assets/img/blog/game3.png" alt="" />

                                            <br /><br />
                                            ‌SecondLive is a 3D virtual environment where users control avatars for socializing,
                                            learning, and business. The project also has an NFT marketplace for
                                            swapping collectibles. In September 2020, SecondLive hosted Binance
                                            Smart Chain’s Harvest Festival as part of its first anniversary. The
                                            virtual expo showcased different projects in the BSC ecosystem for
                                            users to explore and interact with.
                                        </div>
                                        <br />
                                        <div id='crypto'><button id='bullet-1'>2</button>&nbsp;&nbsp;<b>Axie Infinity:&nbsp;&nbsp;
                                            <br /><br />
                                        </b>
                                            <img id='nft-img-1' src="assets/img/blog/game2.jpeg" alt="" />

                                            <br /><br />
                                            Axie Infinity is a play-to-earn game that’s provided players in developing countries an opportunity to earn consistent income.
                                            By purchasing or being gifted three creatures known as
                                            Axies, a player can start farming the Smooth Love Potion (SLP) token.
                                            When sold on the open market, someone could make roughly $200 to $1000
                                            (USD) depending on how much they play and the market price.
                                            <br /><br />
                                            While Axie Infinity doesn’t provide a singular 3D character or
                                            avatar, it gives users the opportunity for a metaverse-like job.
                                            You might have already heard the famous story of Filipinos using
                                            it as an alternative to full-time employment or welfare.

                                        </div>

                                        <br />
                                        <div id='crypto'><button id='bullet-1'>3</button>&nbsp;&nbsp;
                                            <b>Decentraland: &nbsp;&nbsp;
                                            </b>
                                            <br /><br />
                                            <img id='nft-img-1' src="assets/img/blog/game1.jpeg" alt="" />

                                            <br /><br />
                                            Decentraland is an online, digital world that combines social elements with cryptocurrencies, NFTs, and virtual real estate. On top of this, players also take an active role in the governance of the platform. Like other blockchain games,
                                            NFTs are used to represent cosmetic collectibles.
                                            They’re also used for LAND, 16x16 meter land parcels that
                                            users can purchase in the game with the cryptocurrency MANA.
                                            The combination of all of these creates a complex crypto-economy.
                                        </div>
                                    </div>
                                    </div>
                                    <div className="blog-line" />
                                    <div className="blog-details-bottom">
                                        <div className="blog-details-tags">
                                            <ul>
                                                <li className="title" >Tags :</li>
                                                <li><a><h6>Business,</h6></a></li>
                                                <li><a><h6>Work,</h6></a></li>
                                                <li><a><h6>Knowledge,</h6></a></li>
                                                <li><a><h6>Online</h6></a></li>
                                            </ul>
                                        </div>
                                        <div className="blog-details-social">
                                            <ul>
                                                <li>
                                                    <a href="https://www.instagram.com/nft_plus.me/" target="_blank" rel="noreferrer">

                                                        <i className="fab fa-instagram" />
                                                    </a>
                                                </li>

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br /><br />
                            {/* <div className="blog-next-prev">
                                <ul>
                                    <li className="blog-prev">
                                        <Button id='post-button'> <img src="assets/img/icons/left_arrow.png" alt="img" />Previous Post</Button>
                                    </li>
                                    <li className="blog-next">
                                        <Button id='post-button'>Next Post<img src="assets/img/icons/right_arrow.png" alt="img" /></Button>
                                    </li>
                                </ul>
                            </div> */}
                            {/* <div className="comment-reply-box">
                                <h5 className="title">Leave a Reply</h5>
                                <form onSubmit={handleSubmit} className="comment-reply-form">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-grp">
                                                <input type="text"
                                                    name="author"
                                                    placeholder="Author"

                                                    value={formValues.author}
                                                    onChange={handleChange}
                                                />


                                                <p id='author' className="errors_message">{formErrors.author}</p>


                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-grp">
                                                <input type="text" placeholder="Your Email *"
                                                    placeholder="Email"
                                                    name="mail"
                                                    value={formValues.mail}
                                                    onChange={handleChange}
                                                />
                                                <p id='mail' className="errors_message">{formErrors.mail}</p> </div>
                                        </div>
                                    </div>
                                    <div className="form-grp">
                                        <textarea name="message" placeholder="Type Comment Here..." defaultValue={""}
                                            name="textarea"
                                            value={formValues.textarea}
                                            onChange={handleChange}

                                        />
                                        <p id="textarea" className="errors_message">{formErrors.textarea}</p>

                                    </div>
                                    <button type="submit" className="btn">Submit now</button>
                                </form>
                            </div> */}
                        </div>
                        {/* <div className="col-xxl-3 col-lg-4">
                            <aside className="blog-sidebar">
                                <div className="widget">
                                    <h4 className="sidebar-title">Search</h4>
                                    <div className="sidebar-search">
                                        <form action="#">
                                            <input type="text" placeholder="Search ..." />
                                            <button type="submit"><i className="fa fa-search" /></button>
                                        </form>
                                    </div>
                                </div>
                                <div className="widget">
                                    <h4 className="sidebar-title">Categories</h4>
                                    <div className="sidebar-cat-list">
                                        <ul>
                                            <li><a href="/#">Domain Names <i className="fas fa-angle-double-right" /></a></li>
                                            <li><a href="/#">Photography <i className="fas fa-angle-double-right" /></a></li>
                                            <li><a href="/#">Coinbase <i className="fas fa-angle-double-right" /></a></li>
                                            <li><a href="/#">Trading Cards <i className="fas fa-angle-double-right" /></a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="widget">
                                    <h4 className="sidebar-title">Recent Post</h4>
                                    <div className="rc-post-list">
                                        <ul>
                                            <li>
                                                <div className="rc-post-thumb">
                                                    <a href="/blog-details"><img src="assets/img/blog/rc_post_thumb01.jpg" alt="" /></a>
                                                </div>
                                                <div className="rc-post-content">
                                                    <ul className="standard-blog-meta">
                                                        <li><a href="/#"><i className="flaticon-avatar" />Admin</a></li>
                                                        <li><a href="/#"><i className="flaticon-calendar" />Mar 10, 2022</a></li>
                                                    </ul>
                                                    <h5 className="title"><a href="/blog-details">Marketplace is the online shop for the video</a></h5>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="rc-post-thumb">
                                                    <a href="/blog-details"><img src="assets/img/blog/rc_post_thumb02.jpg" alt="" /></a>
                                                </div>
                                                <div className="rc-post-content">
                                                    <ul className="standard-blog-meta">
                                                        <li><a href="/#"><i className="flaticon-avatar" />Admin</a></li>
                                                        <li><a href="/#"><i className="flaticon-calendar" />Mar 10, 2022</a></li>
                                                    </ul>
                                                    <h5 className="title"><a href="/blog-details">Marketplace is the online shop for the video</a></h5>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </aside>
                        </div> */}
                    </div>
                </div>
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover

                />
            </section>
            <Footer />

        </>
    )
}
