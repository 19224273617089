import { useTranslation } from "react-i18next";

export const NFTMintedSuccess = (props, { mintAgain }) => {
  const { t } = useTranslation()
  return (
    <>
      <div className="text-center" style={{ minHeight: '350px', flexDirection: 'row' }}>
        <h5>{t('mint_success')}</h5>

        {/* <div
          className="col-xl-3 col-lg-4 col-md-6 col-sm-6"
          key={props.data.mint.address}
        >
          { }
          <div className="top-collection-item">
            <div className="collection-item-top">
            </div>
            <div className="collection-item-thumb">
              <Link
                to={`/view-nft/${props.data.mint.address}`}
              >
                <span>
                  <img src={props.data.image} alt="" />
                </span>
              </Link>
            </div>
            {console.log('data info', props.data)}
            <div className="collection-item-content">
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <h5 className="title">

                  {props.data.name}
                </h5>
                <h5 className="title">
                  <span className="price" style={{ fontSize: '16px' }}>
                    {props.data.price} {props.data.currency.symbol}
                  </span>
                </h5>

              </div>
              <div style={{ justifyContent: 'flex-start' }}>
                {props.data?.description && props.data?.description.length > 30 ? <Marquee className="title" gradient={false} >
                  <h3 style={{
                    color: '#E039FD',
                    fontSize: '15px',
                    fontWeight: '200',
                    marginTop: '10px',
                    whiteSpace: 'nowrap',
                    // overflow: 'auto',
                  }}><span>{props.data?.description}</span></h3>{' '}
                </Marquee> : <h3 style={{
                  color: '#E039FD',
                  fontSize: '15px',
                  fontWeight: '200',
                  marginTop: '10px',
                  whiteSpace: 'nowrap',
                  // overflow: 'auto',
                }}><span>{props.data?.description}</span></h3>}
              </div>
            </div>
            <div className="collection-item-bottom">
              <ul>
                <li
                  className="bid">
                  <Link
                    to={`/view-nft/${props.data.mint.address}`}
                    className="btn"
                  >
                    "View"

                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div> */}
        <button className="btn mt-5 mx-5" onClick={() => window.location.href = "/mint-nft"}>
          Create Another
        </button>
        {/* <button className="btn mt-5 mx-2" onClick={() => window.location.href = "/my-nfts"}>
          View My NFT
        </button> */}

        <button className="btn mt-5 mx-5" onClick={() => window.location.href = "/my-nfts"}>
          {t('view_all_nfts')}
        </button>
      </div>
    </>
  );
};
