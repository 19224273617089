import React from 'react'
import SellNft from './SellNft'
import WeekFeatures from './WeekFeatures'

const AreaBg = () => {
  return (
    <div className="area-bg">
      {/* HIDE STATIC ELEMENT: FEATURES OF THE WEEK */}
      {/* <WeekFeatures/> */}
      <SellNft />
    </div>
  )
}

export default AreaBg