import React from "react";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import "./ImageUploader.css";

const NFTImageUploader = ({ handleFileChange }) => {
  const { t } = useTranslation();
  return (
    <div className="position-relative nft-uploader" style={{ marginTop: 100 }}>
      <div className="d-flex justify-content-center">
        <div className="text-center">
          <svg
            className="group-hover:animate-bounce group-hover:text-pink-500 mx-auto h-12 w-12 text-gray-300"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 48 48"
            aria-hidden="true"
          >
            <path
              d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <div className="d-flex text-sm">
            <p className="font-medium text-white">{t('upload')}</p>
            {i18n.language === 'en' ? <p style={{ paddingLeft: "10px" }}>{t('drag_drop')}</p> : <p style={{ paddingRight: "10px" }}>{t('drag_drop')}</p>}
          </div>
          <p className="text-sm opacity-75">{t('file_type')}</p>
        </div>
      </div>
      <input
        type="file"
        className="position-absolute z-50 m-0 p-0 top-0 w-100 h-100 outline-none opacity-0"
        onChange={handleFileChange}
        accept=".png, .gif, .jpeg, .jpg"
      />
    </div>
  );
};

export default NFTImageUploader;
