import React, { useCallback, useEffect, useState } from "react";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import TopSellers from "./TopSellers";
import TopSellersLoader from "./TopSellersLoader";
import axios from "axios";

const Banner = () => {
  const [companies, setCompanies] = useState([]);
  const { t } = useTranslation();
  // console.log('language is', i18n.language);

  const getCompanies = useCallback(async () => {
    // axios.get("https://54.82.12.161:3000/creator/get_all_creators")
    // axios.get("http://54.90.239.124:3000/creator/get_all_creators")
    // axios.get(`${process.env.REACT_APP_BASE_URL}creator/get_all_creators`)
    axios.get("https://api.nft-plus.me/creator/get_all_creators")
      .then(function (response) {
        console.log("get all companies", response.data);
        setCompanies(response.data.allCreators);
      })
      .catch(function (error) {
        console.log('error getting companies', error);
      });
  }, []);

  useEffect(() => {
    getCompanies();
  }, [])

  return (
    <>
      <div className="banner-bg">
        <div className="banner-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-8">
                <div className="banner-content">
                  <h2 className="title" style={{ direction: i18n.language === 'en' ? 'ltr' : 'rtl' }} dangerouslySetInnerHTML={{ __html: t('main_heading_spacing') }}>
                    {/* {t('main_heading_first')}
                    {t('main_heading_second')}<span>{t('region')}</span>{t('mena')}</h2> : <h2 className="title"> {t('main_heading_first')}<br />{t('main_heading_second')}<span>{t('mena')}</span>{t('region')}
 */}

                    {/* NFT+ is the first Marketplace <br />
                    for Digital Assets in the <span> MENA</span> Region */}
                  </h2>
                  <p style={{ direction: i18n.language === 'en' ? 'ltr' : 'rtl' }} dangerouslySetInnerHTML={{ __html: t('sub_heading_spacing') }}>
                    {/* Buy and sell digital assets like memberships, tickets,<br />
                    passes, art, stock, and more. */}
                  </p>
                  {/* RTL Styling */}
                  {/* {i18n.language === 'en' ? <Link to="/signup" className="banner-btn">
                    {t('begin')} <i className="fi-sr-arrow-right" />
                  </Link> : <Link to="/signup" className="banner-btn">
                    {t('begin')} <i className="fi-sr-arrow-left" />
                  </Link>} */}
                  <Link to="/signup" className="banner-btn">
                    {t('begin')} <i className="fi-sr-arrow-right" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-6 col-md-8"></div>
            </div>
          </div>
        </div>

        {/* top-seller-area */}
        {/* HIDE TOP SELLERS */}
        {/* {companies.length > 0 ?
          <TopSellers props={companies} /> :
          <TopSellersLoader />} */}
        {/* // } */}
        {/* top-seller-area-end */}
      </div>
    </>
  );
};

export default Banner;
