// import React, { useContext } from "react";
import IndexOne from "../Components/Common/IndexOne/IndexOne";
import Layout from "../Components/Common/Layout/MainLayout";


const Home = () => {
  return (
    <>
      <Layout>
        <IndexOne />
      </Layout>
    </>
  );
};

export default Home;
