import React from 'react'
import Slider from 'react-slick';


function PrevArrow(props) {
  const { classNameName, onClick } = props;
  return (
    <button type='button' classNameName={classNameName} onClick={onClick}><i classNameName="fi-sr-arrow-left"></i></button>
  )
}

function NextArrow(props) {
  const { classNameName, onClick } = props;
  return (
    <button type='button' classNameName={classNameName} onClick={onClick}><i classNameName="fi-sr-arrow-right"></i></button>
  )
}

const TopCollection = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    autoplay: false,
    arrows: false,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    appendArrows: ".top-collection-nav",
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
        }
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        }
      },
    ]

  }

  const dummyNFTs = [
    { name: 'The girl firefly', image: "assets/img/others/top_collection03.jpg", index: '-3' },
    { name: 'Forest Princess', image: "assets/img/others/top_collection04.jpg", index: '-2' },
    { name: 'NFT Painting', image: "assets/img/others/top_collection05.jpg", index: '-1' },
    { name: 'NFT Collection', image: "assets/img/others/top_collection01.jpg", index: '0' },
    { name: 'Colorful Abstract', image: "assets/img/others/top_collection02.jpg", index: '1' },
  ];
  return (
    <section className="top-collection-area">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="section-title mb-40">
              <h2 className="title">Top collections <img src="assets/img/icons/title_icon01.png" alt="" /></h2>
            </div>
          </div>
          <div className="col-md-6">
            <div className="top-collection-nav">
              <button type="button" className="slick-prev slick-arrow">
                {/* <i className="fi-sr-arrow-left"></i> */}
                <i className="fas fa-angle-left"></i>
              </button>
              <button type="button" className="slick-next slick-arrow">
                <i className="fas fa-angle-right"></i>
                {/* <i className="fi-sr-arrow-right"></i> */}
              </button>
            </div>
          </div>
        </div>
        <Slider classNameName="row top-collection-active" {...settings}>
          <div classNameName="col-xl-3">
            <div classNameName="top-collection-item">
              <div classNameName="collection-item-top">
                <ul>
                  <li classNameName="avatar"><a href="/author-profile" classNameName="thumb"><img src="assets/img/others/top_col_avatar.png" alt="" /></a>By <a href="/author-profile" classNameName="name">Jonson</a></li>
                  <li classNameName="info-dots dropdown">
                    <span />
                    <span />
                    <span />
                    <a href="/#" classNameName="dropdown-toggle" data-bs-toggle="dropdown" role="button" aria-expanded="false" />
                    <ul classNameName="dropdown-menu">
                      <li><a href="/nft-marketplace">Artwork</a></li>
                      <li><a href="/nft-marketplace">Action</a></li>
                      <li><a href="/nft-marketplace">Author Action</a></li>
                    </ul>
                  </li>
                </ul>
              </div>
              <div classNameName="collection-item-thumb">
                <a href="/market-single"><img src="assets/img/others/top_collection01.jpg" alt="" /></a>
              </div>
              <div classNameName="collection-item-content">
                <h5 classNameName="title"><a href="/market-single">NFT Collection</a> <span classNameName="price">5.4 ETH</span></h5>
              </div>
              <div classNameName="collection-item-bottom">
                <ul>
                  <li classNameName="bid"><a href="/market-single" classNameName="btn">place a bid</a></li>
                  <li classNameName="wishlist"><a href="/login-register">59</a></li>
                </ul>
              </div>
            </div>
          </div>
          <div classNameName="col-xl-3">
            <div classNameName="top-collection-item">
              <div classNameName="collection-item-top">
                <ul>
                  <li classNameName="avatar"><a href="/author-profile" classNameName="thumb"><img src="assets/img/others/top_col_avatar.png" alt="" /></a>By <a href="/author-profile" classNameName="name">Jonson</a></li>
                  <li classNameName="info-dots dropdown">
                    <span />
                    <span />
                    <span />
                    <a href="/#" classNameName="dropdown-toggle" data-bs-toggle="dropdown" role="button" aria-expanded="false" />
                    <ul classNameName="dropdown-menu">
                      <li><a href="/nft-marketplace">Artwork</a></li>
                      <li><a href="/nft-marketplace">Action</a></li>
                      <li><a href="/nft-marketplace">Author Action</a></li>
                    </ul>
                  </li>
                </ul>
              </div>
              <div classNameName="collection-item-thumb">
                <a href="/market-single"><img src="assets/img/others/top_collection02.jpg" alt="" /></a>
              </div>
              <div classNameName="collection-item-content">
                <h5 classNameName="title"><a href="/market-single">Colorful Abstract</a> <span classNameName="price">5.4 ETH</span></h5>
              </div>
              <div classNameName="collection-item-bottom">
                <ul>
                  <li classNameName="bid"><a href="/market-single" classNameName="btn">place a bid</a></li>
                  <li classNameName="wishlist"><a href="/login-register">59</a></li>
                </ul>
              </div>
            </div>
          </div>
          <div classNameName="col-xl-3">
            <div classNameName="top-collection-item">
              <div classNameName="collection-item-top">
                <ul>
                  <li classNameName="avatar"><a href="/author-profile" classNameName="thumb"><img src="assets/img/others/top_col_avatar.png" alt="" /></a>By <a href="/author-profile" classNameName="name">Jonson</a></li>
                  <li classNameName="info-dots dropdown">
                    <span />
                    <span />
                    <span />
                    <a href="/#" classNameName="dropdown-toggle" data-bs-toggle="dropdown" role="button" aria-expanded="false" />
                    <ul classNameName="dropdown-menu">
                      <li><a href="/nft-marketplace">Artwork</a></li>
                      <li><a href="/nft-marketplace">Action</a></li>
                      <li><a href="/nft-marketplace">Author Action</a></li>
                    </ul>
                  </li>
                </ul>
              </div>
              <div classNameName="collection-item-thumb">
                <a href="/market-single"><img src="assets/img/others/top_collection03.jpg" alt="" /></a>
              </div>
              <div classNameName="collection-item-content">
                <h5 classNameName="title"><a href="/market-single">The girl firefly</a> <span classNameName="price">5.4 ETH</span></h5>
              </div>
              <div classNameName="collection-item-bottom">
                <ul>
                  <li classNameName="bid"><a href="/market-single" classNameName="btn">place a bid</a></li>
                  <li classNameName="wishlist"><a href="/login-register">59</a></li>
                </ul>
              </div>
            </div>
          </div>
          <div classNameName="col-xl-3">
            <div classNameName="top-collection-item">
              <div classNameName="collection-item-top">
                <ul>
                  <li classNameName="avatar"><a href="/author-profile" classNameName="thumb"><img src="assets/img/others/top_col_avatar.png" alt="" /></a>By <a href="/author-profile" classNameName="name">Jonson</a></li>
                  <li classNameName="info-dots dropdown">
                    <span />
                    <span />
                    <span />
                    <a href="/#" classNameName="dropdown-toggle" data-bs-toggle="dropdown" role="button" aria-expanded="false" />
                    <ul classNameName="dropdown-menu">
                      <li><a href="/nft-marketplace">Artwork</a></li>
                      <li><a href="/nft-marketplace">Action</a></li>
                      <li><a href="/nft-marketplace">Author Action</a></li>
                    </ul>
                  </li>
                </ul>
              </div>
              <div classNameName="collection-item-thumb">
                <a href="/market-single"><img src="assets/img/others/top_collection04.jpg" alt="" /></a>
              </div>
              <div classNameName="collection-item-content">
                <h5 classNameName="title"><a href="/market-single">Forest Princess</a> <span classNameName="price">5.4 ETH</span></h5>
              </div>
              <div classNameName="collection-item-bottom">
                <ul>
                  <li classNameName="bid"><a href="/market-single" classNameName="btn">place a bid</a></li>
                  <li classNameName="wishlist"><a href="/login-register">59</a></li>
                </ul>
              </div>
            </div>
          </div>
          <div classNameName="col-xl-3">
            <div classNameName="top-collection-item">
              <div classNameName="collection-item-top">
                <ul>
                  <li classNameName="avatar"><a href="/author-profile" classNameName="thumb"><img src="assets/img/others/top_col_avatar.png" alt="" /></a>By <a href="/author-profile" classNameName="name">Jonson</a></li>
                  <li classNameName="info-dots dropdown">
                    <span />
                    <span />
                    <span />
                    <a href="/#" classNameName="dropdown-toggle" data-bs-toggle="dropdown" role="button" aria-expanded="false" />
                    <ul classNameName="dropdown-menu">
                      <li><a href="/nft-marketplace">Artwork</a></li>
                      <li><a href="/nft-marketplace">Action</a></li>
                      <li><a href="/nft-marketplace">Author Action</a></li>
                    </ul>
                  </li>
                </ul>
              </div>
              <div classNameName="collection-item-thumb">
                <a href="/market-single"><img src="assets/img/others/top_collection05.jpg" alt="" /></a>
              </div>
              <div classNameName="collection-item-content">
                <h5 classNameName="title"><a href="/market-single">NFT Painting</a> <span classNameName="price">5.4 ETH</span></h5>
              </div>
              <div classNameName="collection-item-bottom">
                <ul>
                  <li classNameName="bid"><a href="/market-single" classNameName="btn">place a bid</a></li>
                  <li classNameName="wishlist"><a href="/login-register">59</a></li>
                </ul>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </section >
  )
}

export default TopCollection