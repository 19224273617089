import React from "react";
import Category from "../Components/Common/Category/Category";
import Banner from "../Components/Common/Explore/Banner";
import Layout from "../Components/Common/Layout/MainLayout";
import WeekFeatures from "../Components/Common/IndexOne/WeekFeatures";

import Creatorpage from "../Components/Common/InnerPages/Creatorpage";

const Creators = () => {
  return (
    <>
      <Layout>
        <Banner title="NFT Marketplace" />
        <Category />
        <Creatorpage />
        <WeekFeatures />
      </Layout>
    </>
  );
};

export default Creators;
