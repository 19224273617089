// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB4bcSZbMXey4m2JDBWCUa0sV4sOu-RY1Q",
  authDomain: "nft-plus-notification.firebaseapp.com",
  projectId: "nft-plus-notification",
  storageBucket: "nft-plus-notification.appspot.com",
  messagingSenderId: "330995466962",
  appId: "1:330995466962:web:73c8b2a94e644690dca30b",
  measurementId: "G-HEV9KK9YLD",
};

// Initialize Firebase
initializeApp(firebaseConfig);

const messaging = getMessaging();

// messaging.getToken({ vapidKey: "BITeD6BdNiYZJALn8nYwuu9tjV4PvSR1yMKL13lGinDlyZBM4jFc0w1Fm5vuIkTrsNki_2qgwTTxUbR3vAyyeA0" })

export const requestForToken = async () => {
  const token = await getToken(messaging, {
    vapidKey:
      "BJkY4kdZu6Kwzi4RDjF2NeKl2sBwbqV3YgLOpYdJbfzDNrbhhBLVplNN33yxQjGd4sIxKWUiqHAzo5AYT8rtj6g",
  });
  console.log(token);
  return token;

  // return getToken(messaging, { vapidKey: "BITeD6BdNiYZJALn8nYwuu9tjV4PvSR1yMKL13lGinDlyZBM4jFc0w1Fm5vuIkTrsNki_2qgwTTxUbR3vAyyeA0" })
  //     .then((currentToken) => {
};


export const sendMessageListener = (props) => {
  const title = props.title;
  const body = props.body;
  const icon = props.icon;
  const toToken = props.toToken;
  fetch("https://fcm.googleapis.com/fcm/send", {
    method: "POST",
    headers: {
      Authorization:
        "key=AAAATRDeAtI:APA91bGrtuh9Ze8DTxFueX-AUakemVcYC6F-3X0ZAhwCOleVCZviy70yUFLsiVR5Nja9ST2sHeHG94XromCc2ZWhJylARa5hggX7GUaHCIimA3yzkXKeN9nfFpAJ7WoXLMuTrTP705yH",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      data: {
        notification: {
          title: title,
          body: body,
          icon: icon,
        },
      },
      to: toToken,
    }),
  });
};
