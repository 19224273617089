import React from "react";
import Category from "../Category/Category";
import WeekFeatures from "../IndexOne/WeekFeatures";
import Banner from "./Banner";

import ArtWorks from "./ArtWorks";
import ExploreProduct from "../IndexOne/ExploreProduct";
import { useTranslation } from "react-i18next";

const NftMarket = () => {
  const { t } = useTranslation();
  return (
    <main>
      <Banner title={t('nft_marketplace')} />
      <ExploreProduct className="my-4" />

      {/* 
        <Category/> */}
      {/* <ArtWorks/> */}
      {/* <WeekFeatures/> */}
    </main>
  );
};

export default NftMarket;
