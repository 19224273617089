import React, { useContext, useEffect, useState } from 'react'
import { Connection } from '@solana/web3.js'
import { MetaplexContext } from '../Providers/MetaplexContextProvider';
import PageNotFound from './PageNotFound';
const AdminDashboard = () => {
    const metaplex = useContext(MetaplexContext);

    let admins = ['2eVAYwxrfZiPBQYVpjUKSgXPCLWEaACqaeo6j7ijK48G', '5JvjH24DaZzxqGF6aSFQAQNCuCMs3x9GWYdLDLDosLhM', 'BJ5sBNC7QVRnnUrqqkUuipRAVWPS3HPJapafUyrc3Mxd'];
    const [adminAccess, setAdminAccess] = useState(false);

    useEffect(() => {
        for (let i = 0; i < admins.length; i++) {
            if (metaplex?.publicKey?.toBase58() === admins[i]) {
                setAdminAccess(true);
            }
        };
    }, [metaplex])

    if (!adminAccess) {
        return (
            <PageNotFound></PageNotFound>
        )
    } else {
        return (
            <h2>Admin Dashboard</h2>
        )
    }

}

export default AdminDashboard