import { Link } from "react-router-dom";

function Card({ collection }) {
  return (


    <Link to={`/detail-collection/${collection.collection_id}`}>
      <div style={{ marginTop: '10px', marginBottom: '10px', marginLeft: '10px' }} autoFocus>
        <div style={{ gap: '5px', display: 'flex', alignItems: 'center' }}>
          <img className="br-100 h6 w5 dib" alt={collection.name} src={collection.imagePath} style={{ height: '40px', width: '40px', borderRadius: '10px', fontSize: '15px' }} />

          <p style={{ height: '20px', justifySelf: 'center' }}>
            <h6>{collection.name}</h6></p>
        </div>
      </div>
    </Link>

  );
}

export default Card;
