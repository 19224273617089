import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import i18n from "i18next";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer>
      <div className="footer-top-wrap">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-xl-3 col-lg-4 col-md-5 col-sm-9">
              <div className="footer-widget">
                <div className="footer-logo mb-25">
                  <Link to="/">
                    <img src="assets/img/logo/nftplus.svg" alt="Logo" />
                  </Link>
                </div>
                <p style={{ direction: i18n.language === 'en' ? 'ltr' : 'rtl' }}>
                  {t('footer')}
                </p>
                <ul className="footer-social">
                  {/* HIDE STATIC ELEMENT: OTHER SOCIAL MEDIA */}
                  {/* <li>
                    <Link to="/">
                      <i className="fab fa-facebook-f" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/">
                      <i className="fab fa-twitter" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/">
                      <i className="fab fa-linkedin-in" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/">
                      <i className="fab fa-pinterest-p" />
                    </Link>
                  </li> */}
                  <li>
                    {/* <Link to="/instagram"> */}
                    <a href="https://www.instagram.com/nft_plus.me/" target="_blank">
                      <i className="fab fa-instagram" />
                    </a>
                    {/* </Link> */}
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-sm-6">
              <div className="footer-widget">
                <h4 className="fw-title">{t('useful_links')}</h4>
                <ul className="fw-links">
                  <li style={{ direction: i18n.language === 'en' ? 'ltr' : 'rtl' }}>
                    <Link to="/nft-marketplace">{t('all_nfts')}</Link>
                  </li>
                  <li>
                    <Link to="/SignUp">{t('how_it_works')}</Link>
                  </li>
                  <li>
                    <Link to="/select-type">{t('create')}</Link>
                  </li>
                  <li>
                    <Link to="/nft-marketplace">{t('explore')}</Link>
                  </li>
                  {/* HIDE STATIC ELEMENT: Privacy & Terms */}
                  {/* <li>
                    <Link to="/">Privacy &amp; Terms</Link>
                  </li> */}
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-sm-6">
              <div className="footer-widget">
                <h4 className="fw-title">{t('community')}</h4>
                <ul className="fw-links">
                  {/* HIDE STATIC ELEMENTS: Help Center */}
                  {/* <li>
                    <Link to="/">Help Center</Link>
                  </li> */}
                  <li>
                    <Link to="">{t('contact_us')}</Link>
                  </li>
                  {/* HIDE STATIC ELEMENTS: Partners */}
                  {/* <li>
                    <Link to="/">Partners</Link>
                  </li> */}
                  {/* <li>
                    <a href="/login-register">Suggestions</a>
                  </li> */}
                  {/* HIDE STATIC ELEMENTS: Blog & Newsletter */}
                  {/* <li>
                    <Link to="/blog">Blog</Link>
                  </li> */}
                  {/* <li>
                    <Link to="/login-register">Newsletter</Link>
                  </li> */}
                </ul>
              </div>
            </div>
            {/* HIDE STATIC ELEMENTS: SUBSCRIBE US */}
            {/* <div className="col-xl-3 col-lg-4 col-md-6">
              <div className="footer-widget">
                <h4 className="fw-title">Subscribe Us</h4>
                <form action="#" className="newsletter-form">
                  <input type="email" placeholder="info@youmail.com" />
                  <button type="submit">
                    <i className="flaticon-small-rocket-ship-silhouette" />
                  </button>
                </form>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <div className="copyright-wrap">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="copyright-text">
                <p style={{ direction: i18n.language === 'en' ? 'ltr' : 'rtl' }}>
                  {t('rights_reserved')}{" "}
                  <a href="https://mvp-apps.ae">
                    MVP Application and Game Design
                  </a>
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <ul className="copyright-link-list">
                {/* HIDE STATIC ELEMENTS: Privacy Policy and T&C */}
                {/* <li>
                  <Link to="/login-register">Privacy Policy</Link>
                </li> */}
                {/* <li>
                  <Link to="/login-register">Terms And Condition</Link>
                </li> */}

              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
