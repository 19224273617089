import React from 'react'
import { useTranslation } from "react-i18next";
import Banner from "../Explore/Banner.jsx";
import ExploreProduct from "../IndexOne/ExploreProduct";
const Collections = () => {
    const { t } = useTranslation();
    return (
        <main>

            <Banner title={"NFT Collections"} />
            <ExploreProduct className="my-4" view={"collection"} />

            {/* 
          <Category/> */}
            {/* <ArtWorks/> */}
            {/* <WeekFeatures/> */}
        </main>
    )
}

export default Collections