import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const NFTCardSkelton = () => {
  return (
    <SkeletonTheme baseColor="#202020" highlightColor="#444">
      <div className="top-collection-item">
        <div className="collection-item-top">
          <ul>
            <li className="avatar">
              <Skeleton className="m-1" height="28px" width="28px" circle />

              <Skeleton width="100px" height="18px" />
            </li>
           
          </ul>
        </div>
        <div className="collection-item-thumb">
          <span>
            <Skeleton height="248px" borderRadius="18px" />
          </span>
        </div>
        <div className="collection-item-content">
          <div className="d-flex justify-content-between">
            <Skeleton inline={true} width="100px" />

            <Skeleton inline={true} width="100px" />
          </div>
        </div>
        <div>
          <div className="d-flex justify-content-between">
            <Skeleton inline={true} width="118px" height="28px" />

            <Skeleton inline={true} width="12px" />
          </div>
        </div>
      </div>
    </SkeletonTheme>
  );
};

export default NFTCardSkelton;
