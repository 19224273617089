export const COINGECKO_POOL_INTERVAL = 1000 * 60; // 60 sec
export const COINGECKO_API = 'https://api.coingecko.com/api/v3/';
export const COINGECKO_COIN_PRICE_API = `${COINGECKO_API}simple/price`;

export const solToUSD = async () => {

    try {
        const url = `${COINGECKO_COIN_PRICE_API}?ids=solana&vs_currencies=usd`;
        const resp = await window.fetch(url).then(resp => resp.json());

        return resp.solana.usd;


    } catch (err) {
        console.log(
            "please check your connection and try again later.", err);
    }
    return 4.04;
};
