import React, { useContext, useEffect, useState } from "react";
import Layout from "../Components/Common/Layout/MainLayout";
import Breadcrumb from "../Components/Common/InnerPages/marketsingle/Breadcrumb";
import SingleMarket from "../Components/Common/ViewNFTDetails/SingleMarket";
import { useParams, useLocation } from "react-router";
import { AuctionHouseContext } from "../Providers/AuctionHouseContextProvider";
// import { MetaplexContext } from "../Providers/MetaplexContextProvider";
// import MyNFTDetails from "../Components/Common/ViewNFTDetails/MyNFTDetails";
const ViewNFTDetails = (props) => {
  // const { fromMyNFTs } = props;
  // const { myNFT } = this.props.data;

  // const { myNFTs, metaplex } = useContext(MetaplexContext);
  const { id } = useParams();
  const { listedNfts } = useContext(AuctionHouseContext);
  const [data, setData] = useState(undefined);
  const location = useLocation();
  // console.log('location', location);
  useEffect(() => {

    // console.log('myNFTs', myNFTs.publicKey.toBase58());
    // if (myNftDetails !== undefined || myNftDetails !== null) {
    //   setData(myNftDetails);
    //   console.log('nft deets', myNftDetails)
    // } else {
    setData(listedNfts.find((item) => item.mintAddress.toString() === id));

    // }
  }, [id]);

  const display = props.view;

  if (display === "userProfile") {

    return (
      <>
        <Layout>
          <Breadcrumb profileRoute={"userProfile"} sellerData={location.state.sellerData} publicKey={location.state.id} />
          <SingleMarket data={data} viewOnly={location.state.viewOnly} />
        </Layout>
      </>
    );
  } else if (display === "myNfts") {
    return (
      <>
        <Layout>
          <Breadcrumb profileRoute={"myNfts"} publicKey={location.state.id} />
          <SingleMarket data={data} viewOnly={location.state.viewOnly} />
        </Layout>
      </>
    );
  } else return (
    //   myNftDetails !== undefined || myNftDetails !== null ? <>
    //   <Layout>
    //     <Breadcrumb />
    //     <MyNFTDetails data={data} />
    //   </Layout>
    // </> :
    <>
      <Layout>
        <Breadcrumb />
        <SingleMarket data={data} />
      </Layout>
    </>
  );
};

export default ViewNFTDetails;
