import Modal from "react-bootstrap/Modal";
import "./ListNFTModal.css";
import { useForm } from "react-hook-form";
import { useContext, useEffect, useState } from 'react';
import { MetaplexContext } from "../../../Providers/MetaplexContextProvider";
import Swal from "sweetalert2/dist/sweetalert2.js";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { requestForToken } from "../../../utils/firebase";
import { useWallet } from "@solana/wallet-adapter-react";
import { toMetaplexFileFromBrowser } from "@metaplex-foundation/js";
import { Identicon } from "../Nft/Identicon";

export const EditProfileModal = ({
    showEditModal,
    setShowEditModal,
    setUserProfile,
    isUpdating,
    setIsUpdating,
    data,
}) => {
    const { t } = useTranslation();
    const { publicKey } = useWallet()
    var url = []
    const metaplex = useContext(MetaplexContext);
    const [image, setImage] = useState();
    const [imgFile, setImgFile] = useState();
    const [progress, setProgress] = useState(0);
    const [progressLoading, setProgressLoading] = useState();
    const [progressTotal, setProgressTotal] = useState();
    const handleClose = () => {
        setShowEditModal(false);
        setIsUpdating(false);

    }
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const onSubmit = async (info) => {

        setIsUpdating(true)
        console.log("userdata", info)

        if (imgFile) {
            const uri = await metaplex.metaplex
                .storage()
                .upload(await toMetaplexFileFromBrowser(imgFile))

            console.log(uri, "image uri")

            setUserProfile(info, uri).catch((error) => {
                Swal.fire(`
             ${error}
          `.trim())
            })
        } else {
            const uri = undefined;
            setUserProfile(info, uri).catch((error) => {
                Swal.fire(`
         ${error}
      `.trim())
            })
        }


    };

    const uploadImage = async (e) => {
        setImgFile(e.target.files[0])
        setImage(URL.createObjectURL(e.target.files[0]))
        console.log('uploaded', image);

    }

    useEffect(() => {
        if (data !== undefined) { setImage(data.profile_image) }
    }, [data])


    return (
        <>
            <Modal
                show={showEditModal}
                onHide={handleClose}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    {/* <Modal.Title>{data.name}</Modal.Title> */}
                    <Modal.Title>Edit Profile</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {console.log('user data', data)}
                    {
                        data && data?.profile_image === "" ?
                            <></> :
                            <img src={image} alt="Profile Image" className="profile-image-modal" />}


                    <form onSubmit={handleSubmit(onSubmit)} className="create-item-form">
                        {/* <div className="d-flex justify-content-around align-items-center"> */}
                        <div className="row justify-content-center" style={{ overflowY: 'auto' }}>
                            <div className="form-grp">
                                <label htmlFor="filename">{t('upload_file')}</label>
                                <input type="file" id="profile_image" name="filename" onChange={uploadImage} />
                            </div>
                            {/* <input onChange={uploadImages} name='file' id="file" type="file"
                                    accept="image/png, image/gif, image/jpeg, image/jpg" required /> */}
                            <div className="form-grp">
                                <label for="username">Username</label>
                                <input type="text" id="username" placeholder="NFT Plus" defaultValue={data ? data.username : ""} {...register("username")} />
                            </div>
                            <div className="form-grp">
                                <label for="email">Email</label>
                                <input type="text" id="email" placeholder="your_email@website.com" defaultValue={data ? data.email : ""}  {...register("email")} />
                            </div>
                            <div className="form-grp">
                                <label for="bio">Bio</label>
                                <textarea type="text" id="bio" placeholder="Bio" defaultValue={data ? data.bio : ""}  {...register("bio")} />
                            </div>
                            <div className="form-grp">
                                <label for="instagram_handle">Instagram Link</label>
                                <input type="text" id="instagram_handle" placeholder="Instagram" {...register("instagram_handle")} defaultValue={data ? data.instagram_handle : ""} />
                            </div>
                            <div className="form-grp">
                                <label for="instagram_handle">Twitter Link</label>

                                <input type="text" id="instagram_handle" placeholder="Twitter" {...register("twitter_handle")} defaultValue={data ? data.twitter_handle : ""} />
                            </div>
                            <div className="form-grp">
                                <label for="facebook_handle">Facebook Link</label>
                                <input type="text" id="facebook_handle" placeholder="Facebook" {...register("facebook_handle")} defaultValue={data ? data.facebook_handle : ""} />
                            </div>
                            <div className="form-grp">
                                <label for="youtube_handle">Youtube Link</label>

                                <input type="text" id="youtube_handle" placeholder="Youtube" {...register("youtube_handle")} defaultValue={data ? data.youtube_handle : ""} />
                            </div>
                            <div className="form-grp">
                                <label for="tiktok_handle">TikTok Link</label>

                                <input type="text" id="tiktok_handle" placeholder="TikTok" {...register("tiktok_handle")} defaultValue={data ? data.tiktok_handle : ""} />
                            </div>
                            {/* HIDE DISCORD */}
                            {/* <div className="form-grp">
                                <label for="discord_handle">Discord Link</label>
                                <input type="text" id="discord_handle" placeholder="Discord" {...register("discord_handle")} defaultValue={data ? data.discord_handle : ""} />
                            </div> */}
                            <div className="form-grp">
                                <label for="website">Website</label>

                                <input type="text" id="website" placeholder="Website" {...register("website")} defaultValue={data ? data.website : ""} />
                            </div>
                            <div className="form-grp">
                                <input type="hidden" id="pubkey" {...register("pubkey")} value={metaplex?.publicKey?.toBase58()} />
                            </div>
                        </div>

                        <button
                            className="btn d-block m-auto mt-4 mb-3"
                            style={{ minWidth: "180px" }}
                            type="submit"
                            disabled={isUpdating}
                        >
                            {isUpdating ? "Updating..." : "Update"}
                        </button>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    );
};
