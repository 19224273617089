import Footer from "../Footer/Footer";
import Header from "../Header/Header";

import i18n from 'i18next';
import { useContext, useEffect, useState } from 'react'
import { AuctionHouseContext } from "../../../Providers/AuctionHouseContextProvider";

import "/node_modules/flag-icons/css/flag-icons.min.css";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import cookies from 'js-cookie';
import { useTranslation } from "react-i18next";

// import './bootstrap.css';

const Layout = ({ children }) => {
  const { solPrice, solTPS, network } = useContext(AuctionHouseContext);

  return (
    <>
      <div className="main-content">
        <DynamicDataHeader props={{ solPrice: solPrice, solTPS: solTPS, network: network }} />

        <Header />
        <main>
          <main>{children}</main>
        </main>
        <Footer />
      </div>
    </>
  );
};

export default Layout;

const DynamicDataHeader = ({ props }) => {
  const [selectedLanguageCode, setLngCode] = useState(localStorage.getItem('i18nextLng') || cookies.get('i18next') || 'en');
  const [lngString, setLngString] = useState(selectedLanguageCode === 'en' ? 'English' : 'عربى');
  const languages = [
    {
      code: 'ar',
      name: 'عربى',
      country_code: 'ae',
      dir: "rtl",
    },
    {
      code: 'en',
      name: 'English',
      country_code: 'gb'
    },
  ];


  // const style = document.getElementById('style-direction');
  // const respStyle = document.getElementById('resp-style-direction');
  // if (selectedLanguageCode === 'ar') {
  //   style.href = 'assets/css/arabic.css';
  //   respStyle.href = 'assets/css/responsive_ar.css';
  // } else {
  //   style.href = 'assets/css/style.css';
  //   respStyle.href = 'assets/css/responsive.css';
  // }

  const currentLanguageCode = localStorage.getItem('i18nextLng') || cookies.get('i18next') || 'en';
  const currentLanguage = languages.find(lng => lng.code === currentLanguageCode);

  const { t } = useTranslation();

  // useEffect(() => {
  //   document.body.dir = currentLanguage.dir || "ltr";
  //   const style = document.getElementById('style-direction');
  //   const respStyle = document.getElementById('resp-style-direction');
  //   if (currentLanguageCode === 'ar') {
  //     style.href = 'assets/css/arabic.css';
  //     respStyle.href = 'assets/css/responsive_ar.css';
  //   } else {
  //     style.href = 'assets/css/style.css';
  //     respStyle.href = 'assets/css/responsive.css';
  //   }
  // }, [currentLanguage])

  //console.log('local', localStorage.getItem('i18nextLng'));

  return (
    <div className="data-header" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
      <div className="d-flex justify-content-start"></div>
      <ul
        className="d-flex justify-content-center"
        style={{
          display: 'flex',
          // flexDirection: 'row',
          // transition: 'all 0.3s',
          // transform: 'translate(-50%)',
          justifyContent: 'space-between',
          overflow: 'hidden',
        }}>
        <li className="mx-1" style={{ color: '#D4CDCD', fontSize: 12 }}>
          SOL/USD:<span style={{ color: '#E039FD' }}>{` $${props?.solPrice}`}</span>
        </li>
        <li className="mx-2" style={{ color: '#D4CDCD', fontSize: 12 }}>
          Solana Network:<span style={{ color: '#E039FD' }}>{` ${props?.solTPS?.length > 3 ? props?.solTPS?.toLocaleString() : props?.solTPS} TPS `} {props?.solTPS > 1800 ? '' : <i className="fa fa-exclamation-triangle" aria-hidden="true" style={{ color: 'yellow' }} />}</span>
          {/* {t('solana_network')}:<span style={{ color: '#E039FD' }}>{` ${props?.solTPS.length > 3 ? props?.solTPS?.toLocaleString() : props?.solTPS} TPS `} {props?.solTPS > 1800 ? '' : <i className="fa fa-exclamation-triangle" aria-hidden="true" style={{ color: 'yellow' }} />}</span> */}
        </li>
        <li className="mx-1" style={{ color: '#D4CDCD', fontSize: 12 }}>
          {props?.network?.effectiveType?.toUpperCase()}:<span style={{ color: '#E039FD' }}>{` ${props?.network?.downlink} Mb/s`} {props?.network?.downlink > 5 ? '' : <i className="fa fa-exclamation-triangle" aria-hidden="true" style={{ color: 'yellow' }} />}  </span>
        </li>
        <li className="mx-1" style={{ color: '#D4CDCD', fontSize: 12 }}>
          RTT:<span style={{ color: '#E039FD' }}>{`  ${props?.network?.rtt} ms`}</span>
        </li>


      </ul>
      <div className="d-flex justify-content-end">

        <Dropdown>
          <Dropdown.Toggle id="dropdown-basic"   >
            {lngString}<span className="mx-1"><i className="fa fa-angle-down" aria-hidden="true"></i></span>
          </Dropdown.Toggle>
          <Dropdown.Menu style={{ backgroundColor: "#322C50 !important", color: "white !important" }}>
            {languages.map(({ code, name, country_code }) => (
              <Dropdown.Item key={country_code} as="button" onClick={() => {
                // if (localStorage.getItem('i18nextLng') === null) {
                localStorage.setItem('i18nextLng', code)
                // } 
                setLngCode(code)
                setLngString(name)
                i18n.changeLanguage(code)
                // document.body.dir = code === 'en' ? 'ltr' : 'rtl';
                document.body.dir = 'ltr';
                const style = document.getElementById('style-direction');
                const respStyle = document.getElementById('resp-style-direction');
                // if (code === 'ar') {
                //   style.href = 'assets/css/arabic.css';
                //   respStyle.href = 'assets/css/responsive_ar.css';
                // } else {
                style.href = 'assets/css/style.css';
                respStyle.href = 'assets/css/responsive.css';
                // }


              }
              }
                active={country_code === selectedLanguageCode}
                disabled={code === selectedLanguageCode}
              >
                {/* <span className={`flag-icon flag-icon-${country_code} mx-2`}></span> */}
                {name}
              </Dropdown.Item>
            ))}

          </Dropdown.Menu>
        </Dropdown>
        {/* <div className="bs-dropdown"> */}
        {/* <button className="bs-btn bs-btn-secondary bs-dropdown-toggle" type="bs-button" id="dropdownMenuButton1" data-toggle="bs-dropdown" aria-haspopup="true" aria-expanded="false">
              Language
            </button> */}
        {/* <ul className="bs-dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li className="bs-dropdown-item"><a>Action</a></li>
              <li className="bs-dropdown-item"><a>Action</a></li>
              <li className="bs-dropdown-item"><a>Action</a></li>
            </ul> */}
        {/* </div> */}
      </div>
    </div>
  )
}