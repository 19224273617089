import { useState } from 'react'

const BannerWithLinks = (props) => {
    const [filter, setFilter] = useState("all")

    // useEffect(() => {
    //   localStorage.setItem('filterQuery', filter)
    //   console.log(filter, 'updating in banner')
    // }, [filter])
    // console.log(localStorage.getItem('filterQuery'), 'filter')


    return (
        <>
            <section className={props.backgroundClass ? 'breadcrumb-area breadcrumb-bg breadcrumb-bg-two' : "breadcrumb-area breadcrumb-bg"}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6 col-md-8">
                            <div className="breadcrumb-content text-center">
                                <h3 className="titleNew">{props.title}</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {

                !props.noFilter ?
                    <div className="category-area">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <ul className="category-list">
                                        <li className="active"><button style={{ backgroundColor: 'transparent', borderStyle: 'none', color: 'grey' }} onClick={() => setFilter("all")}><img src="assets3/img/icons/cat_001.png" alt="" /> All</button></li>
                                        {/* <li><button style={{backgroundColor: 'transparent', borderStyle: 'none', color: 'grey'}} onClick={() => setFilter("sports")}><img src="assets3/img/icons/cat_01.png" alt="" /> Sports</button></li> */}
                                        <li><button style={{ backgroundColor: 'transparent', borderStyle: 'none', color: 'grey' }} onClick={() => setFilter("art")}><img src="assets3/img/icons/cat_02.png" alt="" /> Art</button></li>
                                        {/* <li><button style={{backgroundColor: 'transparent', borderStyle: 'none', color: 'grey'}} onClick={() => setFilter("cards")}><img src="assets3/img/icons/cat_03.png" alt="" /> Trading Cards</button></li> */}
                                        {/* <li><button style={{backgroundColor: 'transparent', borderStyle: 'none', color: 'grey'}} onClick={() => setFilter("music")}><img src="assets3/img/icons/cat_04.png" alt="" /> Music</button></li> */}
                                        <li><button style={{ backgroundColor: 'transparent', borderStyle: 'none', color: 'grey' }} onClick={() => setFilter("domain")}><img src="assets3/img/icons/cat_05.png" alt="" /> Domain Names</button></li>
                                        {/* <li><button style={{backgroundColor: 'transparent', borderStyle: 'none', color: 'grey'}} onClick={() => setFilter("memes")}><img src="assets3/img/icons/cat_06.png" alt="" /> Memes</button></li> */}
                                        <li><button style={{ backgroundColor: 'transparent', borderStyle: 'none', color: 'grey' }} onClick={() => setFilter("collectibles")}><img src="assets3/img/icons/cat_07.png" alt="" /> Memberships</button></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div> : null

            }

        </>
    )
}

export default BannerWithLinks
