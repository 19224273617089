/* eslint-disable */
import React from "react";
import BlogDetailsContent from "../Components/Common/Blog/BlogDetailsContent";
import Category from "../Components/Common/Category/Category";
import Banner from "../Components/Common/Explore/Banner";
import Layout from "../Components/Common/Layout/MainLayout";
import NewsButton from "./NewsButton";
import PhantomWallet from "./PhantomWallet";

const BlogDetails = () => {
  return (
    <>
      <Layout>
        <Banner title="News Update"></Banner>
        {/* <Category /> */}
        <BlogDetailsContent />

      </Layout>
    </>
  );
};

export default BlogDetails;
