import { useState } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const AuthorDetailsSkeleton = () => {

    const [width, setWidth] = useState(window.innerWidth);

    const xsScreen = width < 576;
    const smallScreen = width >= 576;
    const mediumScreen = width >= 768;
    const largeScreen = width >= 992;
    const xlScreen = width >= 1200;

    const isMobile = width <= 768;
    const isTablet = width <= 850;
    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    // useEffect(() => {
    //     window.addEventListener("resize", handleWindowSizeChange);
    //     return () => {
    //         window.removeEventListener("resize", handleWindowSizeChange);
    //     };
    // }, []);

    return (
        <SkeletonTheme baseColor="#202020" highlightColor="#444">
            <div className="col-xl-3 col-lg-4 col-md-5 col-sm-6 col-10">
                <aside className="author-profile-wrap">
                    <div className="author-profile-thumb">
                        <Skeleton height="250px" width="100%" style={{ borderRadius: '0px' }} />

                    </div>
                    <div className="author-info">
                        {/* <h5 className="title"> */}
                        <div>
                            <Skeleton marginBottom="0px !important" borderRadius="10px" height="25px" width="150px" inline={true} />
                        </div>
                        {/* </h5> */}
                        <span><Skeleton style={{ marginBottom: 0 }} borderRadius="10px" height="20px" width="100px" inline={true} /></span>
                        <p>
                            <Skeleton height="60px" borderRadius="5px" width="100%" />
                        </p>

                    </div>
                    <ul className="author-collection">
                        <li>
                            <p>
                                <Skeleton height="20px" borderRadius="10px" width="110px" />
                            </p>
                            <span>
                                <Skeleton height="20px" borderRadius="10px" width="50px" />
                            </span>
                        </li>
                    </ul>
                    <div className="author-social">
                        <h6 className="title">
                            <Skeleton height="35px" width="200px" borderRadius="10px" />
                            <br />
                            <ul>
                                <li><a><div className="icon"><Skeleton borderRadius="50%" width="40px" height="40px" inline={true} /></div><Skeleton height="40px" width={width >= 1200 && width <= 1500 ? "140px" : "170px"} borderRadius="10px" /></a></li>
                                <li><a><div className="icon"><Skeleton borderRadius="50%" width="40px" height="40px" /></div> <Skeleton height="40px" width={width >= 1200 && width <= 1500 ? "140px" : "170px"} borderRadius="10px" /></a></li>
                                <li><a><div className="icon"><Skeleton borderRadius="50%" width="40px" height="40px" /></div> <Skeleton height="40px" width={width >= 1200 && width <= 1500 ? "140px" : "170px"} borderRadius="10px" /></a></li>
                                <li><a><div className="icon"><Skeleton borderRadius="50%" width="40px" height="40px" /></div> <Skeleton height="40px" width={width >= 1200 && width <= 1500 ? "140px" : "170px"} borderRadius="10px" /></a></li>
                            </ul>
                        </h6>
                    </div>
                </aside>
            </div>
        </SkeletonTheme>
    );
};

export default AuthorDetailsSkeleton;
