import React from "react";
import Category from "../Components/Common/Category/Category";
import Layout from "../Components/Common/Layout/MainLayout";
import WeekFeatures from "../Components/Common/IndexOne/WeekFeatures";
import Banner from "../Components/Common/Explore/Banner";

const Collections = () => {
  return (
    <>
      <Layout>
        <Banner title="Top collections" />
        <Category />
        <WeekFeatures />
      </Layout>
    </>
  );
};

export default Collections;
