import React, { useState, useEffect, useContext } from "react";
import Category from "../Components/Common/Category/Category";
import Banner from "../Components/Common/Explore/Banner";
import Layout from "../Components/Common/Layout/MainLayout";

import { useLocation } from "react-router-dom";
import { useParams } from "react-router";
import axios from "axios";
import MyNFTLoader from "../Components/Common/MyNFTs/MyNFTLoader";
import { LAMPORTS_PER_SOL } from "@solana/web3.js";
import { MetaplexContext } from "../Providers/MetaplexContextProvider";
import { AuctionHouseContext } from "../Providers/AuctionHouseContextProvider";
import { useTranslation } from "react-i18next";
import { useWallet } from "@solana/wallet-adapter-react";
import Authors from "../Components/Common/InnerPages/Authors";
import ProfileBanner from "../Components/Common/Explore/ProfileBanner";


const AuthorProfile = () => {
  const { metaplex, connection } = useContext(MetaplexContext);
  const { connected } = useWallet();
  const { auctionHouse, listedMintAddress, loadingAuctions, listedNfts } = useContext(AuctionHouseContext);
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState(undefined);// username: '', profile_image: '', twitter_handle: ''
  // const location = useLocation();
  // const userAddress = location.state;
  const { id } = useParams();
  const { t } = useTranslation();

  const getUserProfile = async () => {
    // const data = { pub_key: id === undefined ? "BJ5sBNC7QVRnnUrqqkUuipRAVWPS3HPJapafUyrc3Mxd" : id.toString() }
    const data = { pub_key: id.toString() }
    // axios.get('http://54.90.239.124:3000/user/get_user_profile', {
    // axios.get('https://54.82.12.161:3000/user/get_user_profile', {
    // axios.get(`${process.env.REACT_APP_BASE_URL}user/get_user_profile`, {
    axios.get('https://api.nft-plus.me/user/get_user_profile', {
      params: data
    })
      .then(function (response) {
        console.log("get user profile", response.data.tempUser);
        // console.log("get user ", response.data.und);
        if (response.data?.und === null || response.data?.und === 'null') {
          setUserData({
            profile_image: "",//"assets/img/test-data/default_prof.jpeg",
            username: "Unknown User",
            email: "unknown",
            bio: "",
            instagram_handle: "",
            twitter_handle: "",
            facebook_handle: "",
            youtube_handle: "",
            tiktok_handle: "",
            discord_handle: "",
            website: "",
          });
        } else {
          // axios.get(response.data.tempUser.profile_image)
          //   .then((response) => {
          //     console.log(response, "nftstorage resp - this one")
          setUserData({ ...response.data.tempUser, username: response.data.tempUser.name })
          console.log('userData', userData)
          // })
          // .catch((error) => {
          //   console.log("error getting user profile", error);
          // })


        }
        setLoading(false);


      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    const loadDetails = async () => {
      setLoading(true);
      await getUserProfile();


    };
    // call the function
    loadDetails()
      .catch(() => {
        setLoading(false);
      });
    return (() => {

      loadDetails();
    })



  }, []);

  // const { data } = location.state || { name: 'Olivia Jenar', value: '1,283.12', image: 'assets/img/others/author_profile.png' };
  // const { seller } = props.state || {}
  return (
    <>
      <Layout>
        {/* <Banner title="Authors Profile" /> */}
        <ProfileBanner image={userData?.profile_image === "" ? 'assets/img/bg/breadcrumb_bg.png' : userData?.profile_image} />
        {/* <Category /> */}
        {/* {loading ? <MyNFTLoader numberOfSkeletons={8} /> : */}
        {/* { connected ?  */}
        <Authors props={userData} publicKey={id} />
        {/* // : <div style={{ height: '650px', flexDirection: 'column', display: 'flex', marginTop: '100px', marginBottom: '100px' }}>
            //   <h3 className="text-center my-4">{t('connect_wallet')}</h3>
            // </div>} */}
        {/* } */}
      </Layout>
    </>
  );
};

export default AuthorProfile;
