import React from "react";
import Banner from "../Components/Common/Explore/Banner";
import Layout from "../Components/Common/Layout/MainLayout";
import ExploreProduct from "../Components/Common/IndexOne/ExploreProduct";
import Category from "../Components/Common/Category/Category";

const NftLiveBidding = () => {
  return (
    <>
      <Layout>
        <Banner title="NFT Live Bidding" />
        <Category />
        <ExploreProduct />
      </Layout>
    </>
  );
};

export default NftLiveBidding;
