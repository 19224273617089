import { PublicKey } from "@solana/web3.js";
import { sol } from "@metaplex-foundation/js";
import Swal from 'sweetalert2/dist/sweetalert2.js'

export const fetchAuctionHouse = async ({ metaplex, AUCTION_HOUSE_ADDRESS }) => {
  const auctionHouse = metaplex
    .auctionHouse()
    .findByAddress({ address: AUCTION_HOUSE_ADDRESS })


  return auctionHouse;
};

export const listNFT = async (config, data) => {
  console.log(config, "config");
  console.log('List nft', data);
  const steps = ['1', '2']
  const Queue = Swal.mixin({
    progressSteps: steps,
    confirmButtonText: 'Next >',
    // optional classes to avoid backdrop blinking between steps
    showClass: { backdrop: 'swal2-noanimation', },
    hideClass: { backdrop: 'swal2-noanimation' },
    allowOutsideClick: false

  })

  await Queue.fire({
    title: 'Transaction Approval',
    text: 'Kindly approve the transaction to proceed with listing',
    currentProgressStep: 0,
    // optional class to show fade-in backdrop animation which was disabled in Queue mixin
    showClass: { backdrop: 'swal2-noanimation' },
    allowOutsideClick: false,
    closed: false,

    // hideClass: { backdrop: 'swal2-noanimation' }

  })
  const { listing, sellerTradeState, receipt } = await config.metaplex
    .auctionHouse()
    .list({
      auctionHouse: config.auctionHouse,
      mintAccount: new PublicKey(data.address),
      price: sol(data.price),
      printReceipt: true,
    })



  await Queue.fire({
    icon: "success",
    title: 'Complete',
    text: 'All steps completed!',
    confirmButtonText: 'OK',
    currentProgressStep: 1,
    // optional class to show fade-in backdrop animation which was disabled in Queue mixin
    showClass: { backdrop: 'swal2-noanimation' },
    hideClass: { backdrop: 'swal2-noanimation' }

  })



  return { listing, sellerTradeState, receipt };
};


