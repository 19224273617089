import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import { Identicon } from "../Nft/Identicon";

export const CreatorsModal = ({
    showCreatorModal,
    setShowCreatorModal,
    data,
}) => {
    const { t } = useTranslation();
    const handleClose = () => {
        setShowCreatorModal(false);
    }


    return (
        <>
            <Modal
                show={showCreatorModal}
                onHide={handleClose}
                aria-labelledby="contained-modal-title-vcenter"
                centered

            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {t('creators')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ overflowY: "auto", height: '400px' }}>
                    {(data?.creators.map((creator) => (
                        <div className="market-single-creator">


                            <div className="thumb">

                                <Identicon
                                    alt=""
                                    style={{
                                        borderRadius: "50%",
                                        height: "52px",
                                        width: "52px",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        overflow: "hidden",
                                    }}
                                    address={creator.address}
                                />

                            </div>
                            <div className="info">

                                <span>

                                    <div>
                                        <ul>
                                            <li>
                                                Created by{" "}
                                                <a
                                                    style={{
                                                        fontStyle: "italic",
                                                        color: "#E039FD",
                                                    }}
                                                >
                                                    {creator.address.slice(0, 4) +
                                                        "...." +
                                                        creator.address.slice(41, 44)}
                                                </a>
                                            </li>
                                            <li>
                                                Share{" "}
                                                <a
                                                    style={{
                                                        fontStyle: "italic",
                                                        color: "#E039FD",
                                                    }}
                                                >
                                                    {creator.share} %
                                                </a>
                                            </li>
                                        </ul>
                                    </div>

                                </span>
                            </div>
                        </div>)))

                    }
                </Modal.Body>
            </Modal>
        </>
    );
};
