import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
const LatestNews = () => {
  const { t } = useTranslation();
  return (
    <section className="latest-news-area">
      <div className="container">
        <div className="row">
          <div className="col-md-7">
            <div className="section-title mb-45">
              <h2 className="title">{t('blog')}<img src="assets/img/icons/title_icon01.png" alt="" /></h2>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-xl-4 col-md-6 col-sm-9">
            <div className="latest-news-item" style={{ height: '700px' }}>
              <div className="latest-news-thumb">
                <img id='height' src="assets/img/blog/nftplus-solana-1.png" alt="" />
              </div>
              <div className="latest-news-content">
                <ul className="latest-news-meta">
                  <li><i className="flaticon-user" /><span >NFTPlus</span></li>
                  <li><i className="fi-sr-calendar" /> 5th Sep, 2022</li>
                </ul>
                <h4 className="title"><a href="/blog-details">Overview of NFTs on Solana</a></h4>
                <Link to='/blog-details' className="btn" style={{ float: 'none', border: 'none' }}>{t('read_more')}</Link>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6 col-sm-9">
            <div className="latest-news-item" style={{ height: '700px' }}>
              <div className="latest-news-thumb">
                <img id='height' src="assets/img/blog/secondpage-1.png" alt="" />
              </div>
              <div className="latest-news-content">
                <ul className="latest-news-meta">
                  <li><i className="flaticon-user" /><span >NFTPlus</span></li>
                  <li><i className="fi-sr-calendar" /> 12th Sep, 2022</li>
                </ul>
                <h4 className="title"><a href="/phantomwallet"> Wallet Connection</a></h4>
                <Link to='/phantomwallet' className="btn" style={{ float: 'none', border: 'none' }}>{t('read_more')}</Link>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6 col-sm-9">
            <div className="latest-news-item" style={{ height: '700px' }}>
              <div className="latest-news-thumb">
                <img id='height' src="assets/img/blog/meta.png" alt="" />
              </div>
              <div className="latest-news-content">
                <ul className="latest-news-meta">
                  <li><i className="flaticon-user" /><span >NFTPlus</span></li>
                  <li><i className="fi-sr-calendar" /> 23rd Sep, 2022</li>
                </ul>
                <h4 className="title"><Link to="/metaverse">Introduction to Metaverse</Link></h4>
                <Link to='/metaverse' className="btn" style={{ float: 'none', border: 'none' }}>{t('read_more')}</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default LatestNews
