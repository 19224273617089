import React, { useEffect, useState, useContext } from "react";
import $ from "jquery";
import { Link } from "react-router-dom";
import { WalletMultiButton } from "../WalletAdapterUi/WalletMultiButton";
import Search from "../Searchbar/Search"
import { AuctionHouseContext } from "../../../Providers/AuctionHouseContextProvider";
import { MetaplexContext } from "../../../Providers/MetaplexContextProvider";
import "./Header.css";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import Notifications from "react-notifications-menu";
import { useWallet } from "@solana/wallet-adapter-react";
const OutsideClickHandler = require("react-outside-click-handler").default

const Header = () => {

  const { t } = useTranslation();
  // // console.log(history);
  // const getColor=(curr) =>{
  //   if(history.location.pathname===curr)
  //   {
  //     return("var(--purple-color)")
  //   }
  // }

  const { loadingAuctions, listedNfts, collectionNfts } = useContext(AuctionHouseContext);
  const { publicKey, metaplex } = useContext(MetaplexContext);
  const [isFocused, setFocused] = useState(false);
  const [sale, setSale] = useState([]);
  const { connected } = useWallet();
  // { console.log('pubkey mine', publicKey) }

  const data = [];

  (async () => {

    collectionNfts.forEach(
      (collectionNft) => {
        data.push({
          "imagePath": collectionNft?.json?.image,
          "name": collectionNft?.name,
          "collection_id": collectionNft?.mint?.address
        })
      })

    // console.log(data, "data");
  })()

  function timeSince(date) {

    var seconds = Math.floor((new Date() - date) / 1000);

    var interval = seconds / 31536000;

    if (interval > 1) {
      return Math.floor(interval) + " years";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + " months";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + " days";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + " hours";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + " minutes";
    }
    return Math.floor(seconds) + " seconds";
  }

  const getHistory = async () => {

    let transactionList = await metaplex.connection.getSignaturesForAddress(publicKey, { limit: 20 })
    let signatureList = transactionList.map(transaction => transaction.signature);
    let transactionDetails = await metaplex.connection.getParsedTransactions(signatureList, { maxSupportedTransactionVersion: 0 }, { encoding: "base58" });
    transactionList.forEach((transaction, i) => {
      const date = timeSince(new Date(transaction.blockTime * 1000));
      const transactionInstructions = transactionDetails[i].transaction.message.instructions;
      // console.log(`Transaction No: ${i + 1}`);
      // console.log(transactionDetails[i].transaction);

      try {
        transactionInstructions.forEach(async (instruction, n) => {
          if (instruction.programId.toString() === "hausS13jsjafwWwGqZTUQRmWyvyxn9EQpqMwV1PBBmk") {
            // console.log(`---Instructions ${n + 1}: ${instruction.programId.toString()}`);
            // console.log(`Signature: ${transaction.signature}`);
            // console.log(`Status: ${transaction.confirmationStatus}`);
            // console.log(instruction);
            if (instruction.accounts.length === 22 && instruction.accounts[0].toBase58() !== publicKey.toBase58()) {
              // console.log("Executed sale")
              // console.log(`Time: ${date}`);
              // console.log("Buyer:", instruction.accounts[0].toString());
              // console.log("MintAddress:", instruction.accounts[3].toString());
              // console.log(("-").repeat(20));

              // try {
              //   const nft = await metaplex.nfts().findByMint({ mintAddress: instruction.accounts[3] }).run()

              //   console.log(nft.json.name);
              // setSale(sale => [...sale, {
              //   "Time": date,
              //   "Buyer": instruction.accounts[0].toString(),
              //   "NftName": nft.json.name
              // }])

              // } catch (error) {
              // }

              var requestOptions = {
                method: 'GET',
                redirect: 'follow'
              };

              fetch(`https://api.nft-plus.me/nft/get_nft/?mint_id=${instruction.accounts[3].toString()}&update_new=false`, requestOptions)
                .then(response => response.json())
                .then(result => {
                  // console.log(result, "nft from mongo")
                  setSale(sale => [...sale, {
                    "Time": date,
                    "Buyer": instruction.accounts[0].toString(),
                    "NftName": result.nft.json.name
                  }])
                })
                .catch(error => console.log('nft metadata from mongo error:', error));



            }
          }
        })
      } catch (error) {

      }
    })


  }

  const getNotification = () => {
    // remove getHistory and fetch notifications from api 
  }

  // useEffect(() => {
  //   getHistory()
  //   getNotification()

  //   return () => {
  //     getHistory()
  //     getNotification()
  //   }
  // }, [metaplex])



  useEffect(() => {
    //SubMenu Dropdown Toggle
    if ($(".menu-area li.menu-item-has-children ul").length) {
      $(".menu-area .navigation li.menu-item-has-children").append(
        '<div class="dropdown-btn"><span class="fas fa-angle-down"></span></div>'
      );
    }

    //Mobile Nav Hide Show
    if ($(".mobile-menu").length) {
      var mobileMenuContent = $(".menu-area .push-menu").html();
      $(".mobile-menu .menu-box .menu-outer").append(mobileMenuContent);

      //Dropdown Button
      $(".mobile-menu li.menu-item-has-children .dropdown-btn").on(
        "click",
        function () {
          $(this).toggleClass("open");
          $(this).prev("ul").slideToggle(500);
        }
      );

      $(".menu-backdrop, .mobile-menu .close-btn").click(() => {
        $("body").removeClass("mobile-menu-visible");
      });

      //Menu Toggle Btn
      $(".mobile-nav-toggler").on("click", function () {
        $("body").addClass("mobile-menu-visible");
      });
    }
  }, []);

  useEffect(() => {
    $(".menu-tigger").on("click", function () {
      $(".extra-info,.offcanvas-overly").addClass("active");
      return false;
    });
    $(".menu-close,.offcanvas-overly").on("click", function () {
      $(".extra-info,.offcanvas-overly").removeClass("active");
    });
    /*=============================================
        =     Menu sticky & Scroll to top      =
      =============================================*/
    $(window).on("scroll", function () {
      var scroll = $(window).scrollTop();
      if (scroll < 245) {
        $("#sticky-header").removeClass("sticky-menu");
        $(".scroll-to-target").removeClass("open");
        $("#header-top-fixed").removeClass("header-fixed-position");
      } else {
        $("#sticky-header").addClass("sticky-menu");
        $(".scroll-to-target").addClass("open");
        $("#header-top-fixed").addClass("header-fixed-position");
      }
    });

    /*=============================================
        =    		 Scroll Up  	         =
      =============================================*/
    if ($(".scroll-to-target").length) {
      $(".scroll-to-target").on("click", function () {
        var target = $(this).attr("data-target");
        // animate
        $("html, body").animate(
          {
            scrollTop: $(target).offset().top,
          },
          1000
        );
      });
    }
  }, []);

  const DEFAULT_NOTIFICATION = {
    image: "build/assets/img/profile-pic.svg",
    //   "https://cutshort-data.s3.amazonaws.com/cloudfront/public/companies/5809d1d8af3059ed5b346ed1/logo-1615367026425-logo-v6.png",
    message: "Notification one.",
    detailPage: "/events",
    receivedTime: "12h ago"
  };

  const [notifications, setNotifications] = useState([DEFAULT_NOTIFICATION]);
  const [message, setMessage] = useState("");

  const onClick = () => {
    if (message.length > 0) {
      setNotifications([
        ...notifications,
        {
          ...DEFAULT_NOTIFICATION,
          message
        }
      ]);
      setMessage("");
      alert("notification added");
    }
  };

  const [width, setWidth] = useState(window.innerWidth);

  const isMobile = width <= 768;
  const isTablet = width <= 850;
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const showNotifications = () => {
    setFocused(!isFocused);
    document.getElementById("notif").classList.toggle("show");

  }

  return (
    <header>
      <div id="sticky-header" className="menu-area ">
        <div className="container">
          <div className="row">
            <div className="col-12">

              <div className="mobile-nav-toggler">
                <i className="fas fa-bars" />
              </div>
              <div className="menu-wrap main-menu">
                <nav className="menu-nav">
                  <div className="logo">
                    <Link to="/">
                      <img src="assets/img/logo/nftplus.png" alt="" />
                    </Link>
                  </div>
                  {/* HIDE SEARCHBAR TEMPORARILY */}
                  <div className="header-form">
                    {/* <form action="#">
                      <button>
                        <i className="flaticon-search" />
                      </button>
                      <input type="text" placeholder="Search Artwork" />
                    </form> */}

                    <Search details={data} />
                  </div>
                  <div className="navbar-wrap push-menu main-menu d-none d-lg-flex">
                    <ul className="navigation">
                      {i18n.language === 'en' ? <li >
                        <Link to="/">{t('home')}</Link>
                      </li> : <li className="menu-item-has-children" style={{ direction: i18n.language === 'en' ? 'ltr' : 'rtl' }}>
                        <Link to="#" style={{ pointerEvents: 'none' }}>{t('pages')}</Link>
                        {/* <Link to="/#">Pages</Link> */}
                        <ul className="submenu">
                          <li >
                            {/* <Link to="/mint-nft">{t('create')}</Link> */}
                            <Link to="/select-type">{t('create')}</Link>
                          </li>
                          {/* <li><Link to={{ pathname: `/my-nfts/${publicKey}` }}>{t('my_nfts')}</Link></li> */}
                          <li><Link to={{ pathname: `/my-nfts/` }}>{t('my_nfts')}</Link></li>
                        </ul>
                      </li>}
                      {/* <li style={{ direction: i18n.language === 'en' ? 'ltr' : 'rtl' }}>
                        <Link to={{ pathname: `/my-nfts/` }}>{t('my_nfts')}</Link>
                      </li>} */}

                      {/* {i18n.language === 'en' ?  */}
                      <li >
                        <Link to="/nft-marketplace">{t('explore')}</Link>
                      </li>
                      {/* : <li >
                        <Link to="/mint-nft">{t('create')}</Link>
                      </li>} */}
                      <li>
                        <Link to="/view-collection">{t('collections')}</Link>
                      </li>

                      {i18n.language === 'en' ?
                        // <li >
                        //   <Link to="/mint-nft">{t('create')}</Link>
                        // </li> 
                        <></>
                        : <li >
                          <Link to="/nft-marketplace">{t('explore')}</Link>
                        </li>}
                      {i18n.language === 'en' ? <li className="menu-item-has-children">
                        <Link to="#" style={{ pointerEvents: 'none' }}>{t('pages')}</Link>
                        {/* <Link to="/#">Pages</Link> */}
                        <ul className="submenu">
                          <li >
                            {/* <Link to="/mint-nft">{t('create')}</Link> */}
                            <Link to="/select-type">{t('create')}</Link>
                          </li>
                          {/* <li><Link to={{ pathname: `/my-nfts/${publicKey}` }}>{t('my_nfts')}</Link></li> */}
                          <li><Link to={{ pathname: `/my-nfts/` }}>{t('my_nfts')}</Link></li>
                        </ul>
                      </li> : <li>
                        <Link to="/">{t('home')}</Link>
                      </li>}
                      {/* {i18n.language === 'en' ? <li >
                        <Link to={{ pathname: `/my-nfts/${publicKey}` }}>{t('my_nfts')}</Link>
                      </li> : <li >
                        <Link to="/">{t('home')}</Link>
                      </li>} */}
                      {/* HIDE NOTIFICATIONS */}

                      <li style={{ marginVertical: '10px' }}></li>
                      {(isMobile || isTablet) ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                        <WalletMultiButton />

                      </div> :
                        <></>
                        // <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><WalletMultiButton ><i className="fa fa-wallet" height="200px" width="200px"></i></WalletMultiButton></div>
                      }
                      {/* <li><Link    to="/collections">Collection</Link></li> */}
                      {/* <li className="menu-item-has-children"><a href="/#">Pages</a>
                      <ul className="submenu">
                        <li><Link  to="/activity">Activity</Link></li>
                        <li><Link   to="/category">Category</Link></li>
                        <li><Link   to="/ranking">Ranking</Link></li>
                        <li><Link  to="/creators">Creators</Link></li>
                        <li><Link  to="/market-single">Market Single</Link></li>
                        <li><Link  to="/nft-live-bidding">Live Bidding</Link></li>
                        <li><Link  to="/create-item">Create Item</Link></li>
                        <li><Link   to="/author-profile">Author Profile</Link></li>
                        <li><Link   to="/login-register">Login &amp; Register</Link></li>
                      </ul>
                    </li>
                    <li className="menu-item-has-children"><Link  to="/#">Blog</Link>
                      <ul className="submenu">
                        <li><Link to="/blog">Our Blog</Link></li>
                        <li><Link  to="/blog-details">Blog Details</Link></li>
                      </ul>
                    </li> */}
                    </ul>
                    <div className="icon">
                      <ul style={{ color: 'red' }}>
                        <li>
                          <i className="fi-sr-bg" height="80px" width="80px" color="#fff" />
                        </li>
                      </ul></div>
                  </div>

                  {
                    isMobile || isTablet ? <></> : <div className="navbar-wrap push-menu main-menu d-none d-lg-flex d-md-flex d-sm-flex">

                      <ul>
                        <li className="header-btn">
                          <WalletMultiButton ><i className="fa fa-wallet" height="200px" width="200px"></i></WalletMultiButton>
                          {/* <WalletMultiButton /> */}
                          {/* <i className="fa-solid fa-wallet" height="100px" width="100px" /> */}
                        </li>
                      </ul>
                    </div>
                  }
                </nav>
              </div>
              {/* Mobile Menu  */}

              <div className="mobile-menu">
                <nav className="menu-box">
                  <div className="close-btn">
                    <i className="fas fa-times" />
                  </div>
                  <div className="nav-logo">
                    <a href="/#">
                      <img src="assets/img/logo/nftplus.png" alt="" title="true" />
                    </a>
                  </div>
                  <div className="menu-outer">
                    {/*Here Menu Will Come Automatically Via Javascript / Same Menu as in Header*/}
                    {/* <li className="header-btn">
                      <WalletMultiButton />
                    </li> */}
                  </div>
                  <div className="social-links">
                    <ul className="clearfix">
                      {/* <li>
                        <a href="/#">
                          <span className="fab fa-twitter" />
                        </a>
                      </li> */}
                      {/* <li>
                        <a href="/#">
                          <span className="fab fa-facebook-square" />
                        </a>
                      </li> */}
                      {/* <li>
                        <a href="/#">
                          <span className="fab fa-pinterest-p" />
                        </a>
                      </li> */}
                      <li>
                        <a href="https://www.instagram.com/nft_plus.me/" target="_blank" rel="noreferrer">
                          <span className="fab fa-instagram" />
                        </a>
                      </li>




                      {/* <li>
                        <a href="/#">
                          <span className="fab fa-youtube" />
                        <//detail-collection/Hgfx7nftcZvCzfLrmYZ86HxoPjPVEn6ZgP6Es3mUhFiDa>
                      </li> */}
                    </ul>
                  </div>
                </nav>
              </div>
              <div className="menu-backdrop" />
              {/* End Mobile Menu */}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
