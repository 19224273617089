/* eslint-disable */
import React, { useCallback } from "react";
import Category from "../Components/Common/Category/Category";
import CompanyBanner from "../Components/Common/Explore/CompanyBanner";
import Layout from "../Components/Common/Layout/MainLayout";
import { useContext, useEffect, useState } from "react";
import Company from "../Components/Common/InnerPages/company/Company";
import { useLocation, useParams } from "react-router-dom";
import { AuctionHouseContext } from "../Providers/AuctionHouseContextProvider";
import axios from "axios";
import CompanyBannerLoader from "../Components/Common/Explore/CompanyBannerLoader";
import CompanySkeleton from "../Components/Common/InnerPages/company/CompanySkeleton";


const CompanyProfile = () => {
    const { loadingAuctions, listedNfts, collectionNfts, currentNFTS } = useContext(AuctionHouseContext);
    const { id } = useParams();
    const location = useLocation();


    const [collectionMintAddress, setCollectionMintAddress] = useState(undefined)
    // let [companyProfile, setCompanyProfile] = useState(undefined);
    let [companyProfile, setCompanyProfile] = useState(
        {
            pub_key: '5JvjH24DaZzxqGF6aSFQAQNCuCMs3x9GWYdLDLDosLhM',
            companyName: "MVP APPS",
            companyWebsite: 'nft-plus.me',
            companyEmail: 'mvpappsnft@gmail.com',
            companyPhone: '+971565890689',
            profile_image: "https://www.logodesign.net/logo/abstract-buildings-with-clouds-in-circle-1271ld.png?size=2&industry=company",
            // firebase_notif_token_id
            twitter_handle: '@twitter_handle',
            instagram_handle: '@instagram_handle',
            facebook_handle: '@facebook_handle',
            ownedNfts: [],
        });
    const [loading, setLoading] = useState(false);



    const getCompanyProfile = useCallback(async () => {
        setLoading(true);
        const data = { pub_key: id }
        // axios.get('http://54.90.239.124:3000/creator/get_creator_profile', {
        axios.get('https://54.82.12.161:3000/creator/get_creator_profile', {
            params: data
        })
            .then((response) => {
                console.log("get company profile", response.data.tempCreator);

                setCompanyProfile(
                    {
                        pub_key: response.data.tempCreator.pub_key,
                        companyName: response.data.tempCreator.company_name,
                        companyWebsite: response.data.tempCreator.company_website,
                        companyEmail: response.data.tempCreator.company_email,
                        companyPhone: response.data.tempCreator.company_phone,
                        profile_image: response.data.tempCreator.profile_image ?? "https://www.logodesign.net/logo/abstract-buildings-with-clouds-in-circle-1271ld.png?size=2&industry=company",
                        // firebase_notif_token_id
                        twitter_handle: response.data.tempCreator.twitter_handle,
                        instagram_handle: response.data.tempCreator.instagram_handle,
                        facebook_handle: response.data.tempCreator.facebook_handle,
                        ownedNfts: response.data.tempCreator.owned_nfts,
                    })
                setLoading(false);

            })
            .catch(function (error) {
                console.log("error getting company profile", error);


            });
    }, []);

    // useEffect(() => {
    //     getCompanyProfile()
    //     return () => {
    //         console.log('unmount')
    //         setLoading(false);

    //     }
    // }, [getCompanyProfile])

    return (
        <>
            <Layout>
                {/* <CompanyBanner props={collectionMintAddress !== undefined ? { companyProfile, collectionMintAddress } : companyProfile} /> */}

                <Company props={collectionMintAddress !== undefined ? { companyProfile, collectionMintAddress } : companyProfile} />
            </Layout>
        </>
    );
};

export default CompanyProfile;
