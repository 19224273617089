import React from "react";
import Banner from "../Components/Common/Explore/Banner";
import Layout from "../Components/Common/Layout/MainLayout";

import CategoryItem from "../Components/Common/InnerPages/Categ/CategoryItem";

const Category = () => {
  return (
    <>
      <Layout>
        <Banner title="NFT Browse Category" />
        <CategoryItem />
      </Layout>
    </>
  );
};

export default Category;
