import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import i18n from "i18next";
import { MetaplexContext } from '../../../Providers/MetaplexContextProvider';

const SellNft = () => {
  const { t } = useTranslation();
  const { metaplex } = useContext(MetaplexContext)
  return (
    <section className="sell-nfts-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title mb-45">
              {i18n.language === 'en' ? <h2 className="title" >{t('create_sell_nfts')}{" "}
                <img src="assets/img/icons/title_icon03.png" alt="" />
              </h2> : <div className="title" >
                <h2 className="title" style={{ direction: 'rtl' }} >{t('create_sell_nfts')}{" "}

                </h2>
                <img src="assets/img/icons/title_icon03.png" alt="" />
              </div>}
            </div>
          </div>
        </div>
        <div className="row justify-content-center" style={{ direction: i18n.language === 'en' ? 'ltr' : 'rtl' }}>
          <div className="col-xl-3 col-lg-4 col-md-6">
            <div className="sell-nfts-item">
              <img
                src="assets/img/icons/nfts_01.png" alt=""
                style={{
                  position: 'absolute',
                  left: i18n.language === 'en' ? null : '20px',
                  right: i18n.language === 'en' ? '20px' : null,
                  top: '-30px',
                  zIndex: 1
                }}
              />
              <span className="step-count">{t('step_one')}</span>
              <h5 className="title">{t('create_sell')}</h5>
              <a href='https://phantom.app/' rel="noopener noreferrer" target="_blank">
                <p>{t('create_sell_desc')}</p>
              </a>
            </div>
          </div>
          <div className="col-xl-3 col-lg-4 col-md-6">
            <div className="sell-nfts-item">
              <Link to="/mint-nft">
                <img src="assets/img/icons/nfts_02.png" alt=""
                  style={{
                    position: 'absolute',
                    left: i18n.language === 'en' ? null : '20px',
                    right: i18n.language === 'en' ? '20px' : null,
                    top: '-30px',
                    zIndex: 1
                  }}
                />
                <span className="step-count">{t('step_two')}</span>
                <h5 className="title">{t('create_nfts')}</h5>
                <p>{t('create_desc')}</p>
              </Link>
            </div>
          </div>
          <div className="col-xl-3 col-lg-4 col-md-6">
            <div className="sell-nfts-item">
              <Link to="/nft-marketplace">
                <img src="assets/img/icons/nfts_03.png" alt=""
                  style={{
                    position: 'absolute',
                    left: i18n.language === 'en' ? null : '20px',
                    right: i18n.language === 'en' ? '20px' : null,
                    top: '-30px',
                    zIndex: 1
                  }}
                />
                <span className="step-count">{t('step_three')}</span>
                <h5 className="title">{t('buy_nfts')}</h5>
                <p>{t('own_nfts')}</p>
              </Link>
            </div>
          </div>
          <div className="col-xl-3 col-lg-4 col-md-6">
            <div className="sell-nfts-item">
              {/* <Link to={{ pathname: `/my-nfts/${metaplex?.publicKey?.toString()}` }}> */}
              <Link to={{ pathname: `/my-nfts/` }}>
                <img src="assets/img/icons/nfts_04.png" alt=""
                  style={{
                    position: 'absolute',
                    left: i18n.language === 'en' ? null : '20px',
                    right: i18n.language === 'en' ? '20px' : null,
                    top: '-30px',
                    zIndex: 1
                  }}
                />
                <span className="step-count">{t('step_four')}</span>
                <h5 className="title">{t('list_sale')}</h5>
                <p>{t('nfts_sell_desc')}</p>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SellNft