import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import NFTCard from "../Nft/NftCard";
const NFTList = (props) => {

  const { t } = useTranslation();

  const [loader, setLoader] = useState();


  useEffect(() => {
    setTimeout(() => {
      setLoader("Your NFT Collection is empty")
    }, 2000);//20000);
    setLoader(t('loading'))


  }, [])

  return (
    // <div className="row justify-content-center" style={{ flexDirection: 'row', justifyContent: 'center' }}>
    <>
      {(props.data.length &&
        props.data.map((data) => {
          return (
            <div className="col-xl-4 col-lg-6 col-md-5 col-sm-6 col-10" key={data.address}>
              <NFTCard data={data} />
            </div>
          );
        })) ||
        <div style={{ minHeight: '500px', flexDirection: 'column', display: 'flex' }}>
          <h3 className="text-center my-5">{loader}</h3>
        </div>}
    </>
  );
};

export default NFTList;
