import React, { useEffect } from 'react'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Identicon } from '../../Nft/Identicon';

const dummies = [
    {
        avatar: "assets/img/others/top_col_avatar.png",
        creator: "Jonson",
        image: "assets/img/others/top_collection01.jpg",
        name: "Action Collection",
        price: "5.4 ETH",

    }, {
        avatar: "assets/img/others/top_col_avatar.png",
        creator: "Jonson",
        image: "assets/img/others/top_collection05.jpg",
        name: "Pie Collection",
        price: "5.4 ETH",

    }, {
        avatar: "assets/img/others/top_col_avatar.png",
        creator: "Jonson",
        image: "assets/img/others/top_collection06.jpg",
        name: "Artwork Collection",
        price: "5.4 ETH",

    }, {
        avatar: "assets/img/others/top_col_avatar.png",
        creator: "Jonson",
        image: "assets/img/others/top_collection07.jpg",
        name: "Action Collection",
        price: "5.4 ETH",

    }, {
        avatar: "assets/img/others/top_col_avatar.png",
        creator: "Jonson",
        image: "assets/img/others/top_collection08.jpg",
        name: "Artwork Collection",
        price: "5.4 ETH",

    }, {
        avatar: "assets/img/others/top_col_avatar.png",
        creator: "Jonson",
        image: "assets/img/others/top_collection09.jpg",
        name: "NFT Collection",
        price: "5.4 ETH",

    },
];

export const dummyCollections = [
    {
        image: 'assets/img/others/collection-sample01.png',
        name: 'COLLECTION',
        amount: "23",
    },
    {
        image: 'assets/img/others/collection-sample02.png',
        name: 'COLLECTION',
        amount: "12",
    },
    {
        image: 'assets/img/others/collection-sample03.png',
        name: 'COLLECTION',
        amount: "18",
    },
    {
        image: 'assets/img/others/collection-sample01.png',
        name: 'COLLECTION',
        amount: "23",
    },
]

export const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 1500 }, //min:2000
        items: 4,
        slidesToSlide: 4,
    },
    desktop: {
        breakpoint: { max: 1500, min: 993 }, //max:2000
        items: 3,
        slidesToSlide: 3,//4,
    },
    // smallDesktop: {
    //   breakpoint: { max: 2000, min: 1024 },
    //   items: 2.5,
    //   slidesToSlide: 4,
    // },
    largeTablet: {
        breakpoint: { max: 992, min: 650 }, //max:1024
        items: 2,
        slidesToSlide: 2,
    },
    tablet: {
        breakpoint: { max: 650, min: 464 },
        items: 1.5,
        slidesToSlide: 2,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1,

    }
};

export const CustomRightArrow = ({ onClick, ...rest }) => {
    const {
        onMove,
        carouselState: { currentSlide, deviceType }
    } = rest;
    // onMove means if dragging or swiping in progress.
    return <button className="fa fa-angle-right"
        aria-label="Go to next slide"
        type="button"
        style={{
            position: 'absolute',
            outline: 'none',
            transition: 'all 0.5s',
            borderRadius: '5px',//'35px',
            zIndex: 10, //1000,
            border: 'none',
            background: 'rgba(0, 0, 0, 0.5)',
            minWidth: '43px',
            minHeight: '43px',
            opacity: 1,
            cursor: 1,
            right: 'calc(4% + 1px)',
            alignItems: 'center',
            justifyContent: 'center',
            color: "white",//"#E039FD" //"#6345ED",
        }} onClick={() => onClick()} >
        {/* <i className="fa-solid fa-chevron-right" height="30px" width="30px" style={{  }} /> */}
        {/* <i className="fa fa-angle-right" aria-hidden="true" style={{ height: 40 }} /> */}
    </button>;
};

export const CustomLeftArrow = ({ onClick, ...rest }) => {
    const {
        onMove,
        carouselState: { currentSlide, deviceType }
    } = rest;
    // onMove means if dragging or swiping in progress.
    return <button className="fa fa-angle-left"
        aria-label="Go to previous slide"
        type="button"
        style={{
            position: 'absolute',
            outline: 'none',
            transition: 'all 0.5s',
            borderRadius: '5px',
            zIndex: 10,
            border: 'none',
            background: 'rgba(0, 0, 0, 0.5)',
            minWidth: '43px',
            minHeight: '43px',
            opacity: 1,
            cursor: 1,
            left: 'calc(4% + 1px)',
            alignItems: 'center',
            justifyContent: 'center',
            color: "white"
        }} onClick={() => onClick()} >
    </button>;
};


const Company = ({ props }) => {
    const seller = props || { name: 'Olivia Jenar', value: '1,283.12', image: 'assets/img/others/author_profile.png' };
    // console.log('seller props', props);
    const publicKey = '5JvjH24DaZzxqGF6aSFQAQNCuCMs3x9GWYdLDLDosLhM';
    return (
        <div className="user-profile-area">
            <div className="container">
                <div className="row justify-content-center">
                    {/* hi i removed order-2 order-lg-0 from the classname below because the profile details showed up under the NFTs when changing to tablet/mobile mode. You look fantastic today. */}
                    {/* <div className="col-xl-3 col-lg-4 col-md-6"> */}
                    {/* <div className="col-xl-9 col-lg-8"> */}
                    <div >
                        <div className="col-xl-3 col-lg-4 col-md-5 col-sm-6 col-10">
                            <aside className="user-profile-wrap">

                                <div className="user-profile-thumb">
                                    {/* {userData && (userData?.profile_image === "" || userData?.profile_image === undefined) ? */}
                                    <Identicon
                                        alt=""
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                        }}
                                        address={publicKey?.toString()}
                                    />
                                    {/* : <img src={userData?.profile_image ?? "assets/img/test-data/default_prof.jpeg"} height="100%" width="100%" alt="" />} */}
                                </div>
                                <div className="user-info">
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <h5 className="title">
                                            {/* {userData && userData?.username === undefined && (publicKey !== undefined || publicKey.toString().length !== 0) ?  */}
                                            {publicKey.toString().slice(0, 3) + "..." + publicKey.toString().slice(-3)}
                                            {/* //  : userData?.username} */}
                                            {/* <img src="assets/img/icons/star.svg" alt="" /> */}
                                        </h5>
                                    </div>
                                    <p>Myself Olivia_ ipsum dolor amet this consectetur adipisicing elit. Quis non fugit totam laborio.</p>
                                </div>

                                <ul className="user-collection">
                                    <li>
                                        <p>My Owned Asset</p>
                                        <span>27</span>
                                    </li>
                                    <li>
                                        <p>My Wallet Balance</p>
                                        <span>2.0345 SOL</span>
                                    </li>
                                </ul>

                                <div className="user-social">
                                    <h6 className="title">Follow Social Media :</h6>
                                    <ul>
                                        <li><a href="/#"><div className="icon"><i className="fab fa-facebook-f" /></div> Facebook / @{props?.facebook_handle}</a></li>
                                        <li><a href="/#"><div className="icon"><i className="fab fa-twitter" /></div> Twitter / @{props?.twitter_handle}</a></li>
                                        <li><a href="/#"><div className="icon"><i className="fab fa-instagram" /></div> Whatsapp / @{props?.instagram_handle}</a></li>
                                        <li><a href="/#"><div className="icon"><i className="fab fa-youtube" /></div> Youtube / @joys_Avi</a></li>
                                    </ul>
                                </div>

                            </aside>
                        </div>
                        <div className="company-next-collection" >

                            <p style={{ marginTop: '10px' }}>NEXT NFT COLLECTION:</p>
                            <h2 className="company-strokeme" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>To Be Announced</h2>
                            {/* <ul style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                            <li style={{ flex: 'display', flexDirection: 'column' }}><div className="company-strokeme">35</div><p>DAYS</p></li>
                            <li style={{ flex: 'display', flexDirection: 'column' }}><div className="company-strokeme">15</div><p>HOURS</p></li>
                            <li style={{ flex: 'display', flexDirection: 'column' }}><div className="company-strokeme">42</div><p>MINS</p></li>
                            <li style={{ flex: 'display', flexDirection: 'column' }}><div className="company-strokeme">09</div><p>SECS</p></li>
                            </ul> */}
                        </div>
                        {/* <div className="col-xl-9 col-lg-8"> */}
                        <div>

                            <p className="company-collection-title">COLLECTIONS</p>
                            {/* <div> */}
                            <Carousel
                                infinite={true}
                                responsive={responsive}
                                customRightArrow={<CustomRightArrow />}
                                customLeftArrow={<CustomLeftArrow />}
                                // containerClass="react-multi-carousel-list"

                                ssr
                            >
                                {dummyCollections.map((collection, index) => {
                                    return (
                                        <div
                                            // className="mx-2" //"col-xl-3 col-lg-4 col-md-6 col-sm-6"
                                            key={index}
                                        >
                                            {/* <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6"> */}
                                            <div className="company-collection-item">
                                                <img src={collection.image} alt="" />
                                                <h5>{collection.name}<span>{collection.amount}</span></h5>
                                            </div>
                                            {/* </div> */}


                                        </div>
                                    );
                                })}</Carousel>
                            {/* </div> */}
                            {/* <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                <div className="company-collection-item">
                                    <img src="assets/img/others/collection-sample01.png" alt="" />
                                    <h5>COLLECTION <span>23</span></h5>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                <div className="company-collection-item">
                                    <img src="assets/img/others/collection-sample02.png" alt="" />
                                    <h5>COLLECTION <span>12</span></h5>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                <div className="company-collection-item">
                                    <img src="assets/img/others/collection-sample03.png" alt="" />
                                    <h5>COLLECTION <span>18</span></h5>
                                </div>
                            </div>
                        </div> */}

                        </div>
                        <div className="company-product-meta" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <p>HOT ONES</p>
                            <ul>
                                <li className="active"><a href="/user-profile">All nfts</a></li>
                                <li><a href="/company-profile">Virtual Worlds</a></li>
                                <li><a href="/company-profile">Collectibles</a></li>
                                <li><a href="/company-profile">Music</a></li>
                            </ul>
                        </div>
                        <div className="row justify-content-center">
                            {dummies.map((dummy) =>
                                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-10">
                                    {/* <div className="col-xl-4 col-md-6 col-sm-6"> */}
                                    <div className="top-collection-item">
                                        <div className="collection-item-top">
                                            <ul>
                                                <li className="avatar"><a href="/user-profile" className="thumb"><img src={dummy.avatar} alt="" /></a>By <a href="/user-profile" className="name">{dummy.creator}</a></li>

                                            </ul>
                                        </div>
                                        <div className="collection-item-thumb">
                                            <a href="/market-single"><img src={dummy.image} alt="" /></a>
                                        </div>
                                        <div className="collection-item-content">
                                            <h5 className="title"><a href="/market-single">{dummy.name}</a> <span className="price">{dummy.price}</span></h5>
                                        </div>
                                        <div className="collection-item-bottom">
                                            <ul style={{ justifyContent: 'space-between' }}>
                                                <li className="bid"><a href="/market-single" className="btn">place a bid</a></li>
                                                {/* HIDE FAVORITE / WISHLIST */}
                                                {/* <li className="wishlist"><a href="/login-register">59</a></li> */}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            )}

                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Company