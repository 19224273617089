import { useWallet } from "@solana/wallet-adapter-react";
import type { FC } from "react";
import { useNavigate } from "react-router-dom";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  useContext
} from "react";
import type { ButtonProps } from "./Button";
import { Button } from "./Button";
import { useWalletModal } from "./useWalletModal";
import { WalletConnectButton } from "./WalletConnectButton";
import { WalletIcon } from "./WalletIcon";
import { WalletModalButton } from "./WalletModalButton";
import { Link } from "react-router-dom";
import "./WalletMultiButton.css";
import { useTranslation } from "react-i18next";
import { MetaplexContext } from "../../../Providers/MetaplexContextProvider";

export const WalletMultiButton: FC<ButtonProps> = ({ children, ...props }) => {
  const { t } = useTranslation();
  const metaplex = useContext(MetaplexContext);
  const { publicKey, wallet, disconnect } = useWallet();
  const { setVisible } = useWalletModal();
  const [copied, setCopied] = useState(false);
  const [active, setActive] = useState(false);
  const ref = useRef<HTMLUListElement>(null);
  let navigate = useNavigate();
  let admins = ['2eVAYwxrfZiPBQYVpjUKSgXPCLWEaACqaeo6j7ijK48G', '5JvjH24DaZzxqGF6aSFQAQNCuCMs3x9GWYdLDLDosLhM', 'BJ5sBNC7QVRnnUrqqkUuipRAVWPS3HPJapafUyrc3Mxd'];
  const [adminAccess, setAdminAccess] = useState(false);

  useEffect(() => {

    for (let i = 0; i < admins.length; i++) {
      if (metaplex?.publicKey?.toBase58() === admins[i]) {
        setAdminAccess(true);
      }
    };
  }, [metaplex])
  const base58 = useMemo(() => publicKey?.toBase58(), [publicKey]);
  const content = useMemo(() => {
    if (!wallet || !base58) return null;
    return base58.slice(0, 4) + ".." + base58.slice(-4);
  }, [children, wallet, base58]);

  const copyAddress = useCallback(async () => {
    if (base58) {
      await navigator.clipboard.writeText(base58);
      setCopied(true);
      setTimeout(() => setCopied(false), 400);
    }
  }, [base58]);

  const openDropdown = useCallback(() => {
    setActive(true);
  }, []);

  const closeDropdown = useCallback(() => {
    setActive(false);
  }, []);

  const openModal = useCallback(() => {
    setVisible(true);
    closeDropdown();
  }, [closeDropdown, setVisible]);

  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      const node = ref.current;

      // Do nothing if clicking dropdown or its descendants
      if (!node || node.contains(event.target as Node)) return;

      closeDropdown();
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, closeDropdown]);

  if (!wallet)
    return <WalletModalButton {...props}>{children}</WalletModalButton>;
  if (!base58)
    return <WalletConnectButton {...props}>{children}</WalletConnectButton>;


  return (
    <div className="wallet-adapter-dropdown">
      <Button
        aria-expanded={active}
        className="wallet-adapter-button-trigger"
        style={{ pointerEvents: active ? "none" : "auto", ...props.style }}
        onClick={openDropdown}
        startIcon={<WalletIcon wallet={wallet} />}
        {...props}
      >
        {content != null ? content : children}
      </Button>
      <ul
        aria-label="dropdown-list"
        className={`wallet-adapter-dropdown-list ${active && "wallet-adapter-dropdown-list-active"
          }`}
        ref={ref}
        role="menu"
      >
        <li
          onClick={copyAddress}
          className="wallet-adapter-dropdown-list-item"
          role="menuitem"
        >
          {copied ? t("copied") : t("copy_address")}
        </li>
        <li
          onClick={() => {
            // navigate(`/my-nfts/${metaplex.publicKey.toString()}`);
            navigate(`/my-nfts/`);
          }}
          className="wallet-adapter-dropdown-list-item"
          role="menuitem"
        >
          {/* <Link to={{ pathname: `/my-nfts/${metaplex.publicKey.toString()}` }}>{t("my_nfts")}</Link> */}
          <Link to={{ pathname: `/my-nfts/` }}>{t("my_nfts")}</Link>
        </li>
        {/* HIDE CHANGE WALLET */}
        {/* <li
          onClick={openModal}
          className="wallet-adapter-dropdown-list-item"
          role="menuitem"
        >
          Change wallet
        </li> */}


        {adminAccess ?
          <li onClick={() => { navigate("/admin") }}
            className="wallet-adapter-dropdown-list-item"
            role="menuitem">{t('admin')}</li> : <></>}
        <li
          onClick={disconnect}
          className="wallet-adapter-dropdown-list-item"
          role="menuitem"
        >
          {t("disconnect")}
        </li>
      </ul>
    </div>
  );
};
