
import { Link } from 'react-router-dom';



const PageNotFound = () => {

    return (
        <section className="">
            <br></br>

            <div style={{ position: 'relative', height: '800px', width: '100%', textAlign: 'center', transform: 'translate(0%, 50%)' }}>
                <h2 style={{ justifySelf: 'center', }}>404: Page Not Found</h2>
                <br></br>
                <Link to="/"><button className="btn">Go Back to Home</button></Link>

            </div>
        </section>
    );
};

export default PageNotFound;