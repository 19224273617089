/* eslint-disable */
import React from "react";
import BlogContent from "../Components/Common/Blog/BlogContent";
import Category from "../Components/Common/Category/Category";
import Banner from "../Components/Common/Explore/Banner";
import Layout from "../Components/Common/Layout/MainLayout";

const Blog = () => {
  return (
    <>
      <Layout>
        <Banner title="Latest News" />
        <Category />
        <BlogContent />
      </Layout>
    </>
  );
};

export default Blog;
