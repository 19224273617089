import { useWallet } from "@solana/wallet-adapter-react";
import type { FC, MouseEvent } from "react";
import React, { useCallback, useEffect, useState } from "react";
import type { ButtonProps } from "./Button";
import { Button } from "./Button";
import { useWalletModal } from "./useWalletModal";

export const WalletModalButton: FC<ButtonProps> = ({
  children = "Select Wallet",
  onClick,
  ...props
}) => {
  const { visible, setVisible } = useWalletModal();

  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;
  const isTablet = width <= 850;
  const { connected } = useWallet();

  const handleClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      if (onClick) onClick(event);
      if (!event.defaultPrevented) setVisible(!visible);
    },
    [onClick, setVisible, visible]
  );

  return (isMobile || isTablet) && !connected ? (
    <a
      href="https://phantom.app/ul/browse/https%3A%2F%2Fnft-plus.me?ref=https%3A%2F%2Fnft-plus.me"
      target="_blank"
    >
      <Button
        className="wallet-adapter-button-trigger"
        onClick={handleClick}
        {...props}
      >
        {children}
      </Button>
    </a>
  ) : (
    <Button
      className="wallet-adapter-button-trigger"
      onClick={handleClick}
      {...props}
    >
      {children}
    </Button>
  );
};
