import React from 'react'
import Layout from "../Components//Common/Layout/MainLayout";
import Collections from '../Components/Common/Collection/Collections';
export const ViewCollection = () => {
    return (

        <>
            <Layout>
                <Collections />
            </Layout>
        </>

    )
}
