import { getMetaDataFromURI } from "../../Api/Solana/getMetaData";
const myNftAdapter = (nfts: any) => {
  return nfts?.map((data: any) => {
    return {
      address: data?.address,
      mintAddress: data?.mint?.address,
      updateAuthorityAddress: data?.updateAuthorityAddress,
      description: data?.json?.description,
      name: data?.name,
      symbol: data?.symbol,
      uri: data?.json?.image,
      isMutable: data?.isMutable,
      sellerFeeBasisPoints: data?.sellerFeeBasisPoints,
      creators: data?.creators.map((item: any) => {
        return {
          address: item?.address,
          verified: item?.verified,
          share: item?.share,
        };
      }),
    };
  });
};

export { myNftAdapter };
