import { PublicKey } from "@solana/web3.js";
export const cancelListing = async ({
  tradeStateAddress,
  auctionHouse,
  metaplex,
}) => {
  try {

    const listing = await metaplex
      .auctionHouse().findListingByTradeState({
        tradeStateAddress: tradeStateAddress,
        auctionHouse
      });

    const { response } = await metaplex
      .auctionHouse()
      .cancelListing({ auctionHouse, listing })

    console.log(response);
    return response;
  } catch (err) {
    console.log(err);
  }
};
