import React from 'react'
import { Link } from 'react-router-dom'

const ProfileBanner = (props) => {
    return (
        <section
            style={{
                backgroundImage: `url(${props.image})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                padding: '260px 0 140px',
                filter: 'blur(20px)',
                position: 'relative',
                pointerEvents: 'none',
                cursor: 'default',
                zIndex: -2,
            }}>

            <div className="container">
                <div className="row justify-content-center">
                    {/* <div className="home-back-btn">
            <Link to="/" style={{ "margin-left": "20px" }}>go back to home</Link>
          </div> */}
                    <div className="col-lg-6 col-md-8">

                        {/* <div className="breadcrumb-content text-center">

              <h3 className="title">{props.title}</h3>
            </div> */}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ProfileBanner