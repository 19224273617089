import NFTCardSkelton from "../Nft/NftCardSkelton";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { useLocation } from 'react-router-dom';

/*useLocation reads the url for current path to get the route*/

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 1500 }, //min:2000
    items: 4,
    slidesToSlide: 4,
  },
  desktop: {
    breakpoint: { max: 1500, min: 993 }, //max:2000
    items: 3,
    slidesToSlide: 3,//4,
  },
  // smallDesktop: {
  //   breakpoint: { max: 2000, min: 1024 },
  //   items: 2.5,
  //   slidesToSlide: 4,
  // },
  largeTablet: {
    breakpoint: { max: 992, min: 650 }, //max:1024
    items: 2,
    slidesToSlide: 2,
  },
  tablet: {
    breakpoint: { max: 650, min: 464 },
    items: 1.5,
    slidesToSlide: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1,

  }
};

const CustomRightArrow = ({ onClick, ...rest }) => {
  const {
    onMove,
    carouselState: { currentSlide, deviceType }
  } = rest;
  // onMove means if dragging or swiping in progress.
  return <button className="fa fa-angle-right"
    aria-label="Go to next slide"
    type="button"

    style={{
      position: 'absolute',
      outline: 'none',
      transition: 'all 0.5s',
      borderRadius: '5px',//'35px',
      zIndex: 1000,
      border: 'none',
      background: 'rgba(0, 0, 0, 0.5)',
      minWidth: '43px',
      minHeight: '43px',
      opacity: 1,
      cursor: 1,
      right: 'calc(4% + 1px)',
      // top: '1px',
      alignItems: 'center',
      justifyContent: 'center',
      color: "#E039FD" //"#6345ED",
    }} onClick={() => onClick()} >
    {/* <i className="fa-solid fa-chevron-right" height="30px" width="30px" style={{  }} /> */}
    {/* <i className="fa fa-angle-right" aria-hidden="true" style={{ height: 40 }} /> */}
  </button>;
};

const CustomLeftArrow = ({ onClick, ...rest }) => {
  const {
    onMove,
    carouselState: { currentSlide, deviceType }
  } = rest;
  // onMove means if dragging or swiping in progress.
  return <button className="fa fa-angle-left"
    aria-label="Go to previous slide"
    type="button"
    style={{
      position: 'absolute',
      outline: 'none',
      transition: 'all 0.5s',
      borderRadius: '5px',
      zIndex: 1000,
      border: 'none',
      background: 'rgba(0, 0, 0, 0.5)',
      minWidth: '43px',
      minHeight: '43px',
      opacity: 1,
      cursor: 1,
      left: 'calc(4% + 1px)',
      alignItems: 'center',
      justifyContent: 'center',
      color: "#E039FD"
    }} onClick={() => onClick()} >
  </button>;
};

function CheckRoute() {
  try {
    const location = useLocation();
    return location.pathname
  } catch (error) {
    console.log('cant get path', error);
  }

}


const MyNFTLoader = (props) => {
  let currentRoute = CheckRoute();
  /*eslint-disable */

  if (currentRoute !== '/') {
    return (
      <div className="row justify-content-center" >

        {Array.apply(null, { length: props.numberOfSkeletons }).map((e, i) => {
          return (
            <div key={i} className={props.userProfile ? "col-xl-4 col-lg-6 col-md-5 col-sm-6 col-10" : "col-xl-3 col-lg-4 col-md-5 col-sm-6"}>
              <NFTCardSkelton />
            </div>
          );
        })}

      </div>
    );
  } else {
    return (
      // <div className="row justify-content-center" >
      <div>
        <Carousel
          swipeable={false}
          draggable={false}
          responsive={responsive}
          customRightArrow={<CustomRightArrow />}
          customLeftArrow={<CustomLeftArrow />}
          renderButtonGroupOutside
          arrows={false}
        >

          {Array.apply(null, { length: props.numberOfSkeletons }).map((e, i) => {
            return (
              <div key={i} className="mx-2" >
                <NFTCardSkelton />
              </div>
            );
          })}
        </Carousel>
      </div>
    )
  }

};

export default MyNFTLoader;
