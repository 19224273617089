import React from "react";
import Category from "../Components/Common/Category/Category";
import Banner from "../Components/Common/Explore/Banner";
import Layout from "../Components/Common/Layout/MainLayout";
import RankingPage from "../Components/Common/InnerPages/RankingPage";

const Ranking = () => {
  return (
    <>
      <Layout>
        <Banner title="Nft Ranking" />
        <Category />
        <RankingPage />
      </Layout>
    </>
  );
};

export default Ranking;
