import React from "react";
import Category from "../Components/Common/Category/Category";
import Banner from "../Components/Common/Explore/Banner";
import Layout from "../Components/Common/Layout/MainLayout";
import CrItem from "../Components/Common/InnerPages/CrItem";

const CreateItem = () => {
  return (
    <>
      <Layout>
        <Banner title="Create Item" />
        <Category />
        <CrItem />
      </Layout>
    </>
  );
};

export default CreateItem;
