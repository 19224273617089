import React from "react";
import { Link, useNavigate } from "react-router-dom";

const Breadcrumb = (props) => {
  const navigate = useNavigate();
  return (
    <div className="breadcrumb-area market-single-breadcrumb-area mt-180">
      <div className="breadcrumb-bg" />
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="market-single-breadcrumb">
              <div className="home-back-btn">
                {props && props?.profileRoute === "userProfile" ? <Link to={`/author-profile/${props.publicKey}`}>back to profile</Link> : props?.profileRoute === "myNfts" ? <button onClick={() => navigate(-1)} style={{ background: 'none', border: 'none' }}><Link to={`/my-nfts/`}>back to my nfts</Link></button> : <Link to="/">go back to home</Link>}
              </div>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  {props && props?.profileRoute === "userProfile" ?
                    <li className="breadcrumb-item">
                      <Link to={`/author-profile/${props.publicKey}`}>{props.sellerData?.username !== undefined && props.sellerData?.username !== '' ? props.sellerData.username : props.publicKey.toString().slice(0, 3) + "..." + props.publicKey.toString().slice(-3)}</Link>
                    </li>
                    : props?.profileRoute === "myNfts" ? <li className="breadcrumb-item">
                      <Link to="/my-nfts/">My NFTs</Link>
                    </li> : <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>}
                  <li className="breadcrumb-item active" aria-current="page">
                    NFT Details
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Breadcrumb;
