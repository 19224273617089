import React from 'react'
import Table from 'react-bootstrap/esm/Table'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

const TransactionLoader = () => {
    return (
        <SkeletonTheme baseColor="#202020" highlightColor="#444">
            <div className="col-xl-12 col-lg-12">
                <div //className="activity-table-wrap" 
                    style={{ marginTop: '40px' }}>
                    <div style={{
                        display: 'block',
                        alignItems: 'flex-start',
                    }}>
                        <h4 class="title">Transaction History</h4>
                        {/* <h4 class="title"><Skeleton width="225px" height="30px" /></h4> */}
                    </div>
                    <div className="tab-content" id="myTabContent" >

                        <Table className="table activity-table" responsive>
                            <thead>
                                <tr>
                                    {/* <th scope="col">Name</th> */}
                                    <th scope="col">Name</th>
                                    <th scope="col">Transaction Id</th>
                                    <th scope="col">Transaction Type</th>
                                    <th scope="col">Time</th>
                                    <th scope="col">Total Amount</th>
                                    <th scope="col">Buyer</th>
                                    <th scope="col" class="time">Seller</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    {/* <td>Cyber Monkey #9</td> */}
                                    <td style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                        <Skeleton width="100px" />
                                        <Skeleton width="60px" />
                                    </td>
                                    <td>
                                        <Skeleton width="60px" />
                                    </td>
                                    <td>
                                        <Skeleton width="80px" />
                                        <Skeleton width="60px" />
                                    </td>
                                    <td>
                                        <Skeleton width="80px" />
                                        <Skeleton width="60px" />
                                    </td>
                                    <td>
                                        <Skeleton width="80px" />
                                        <Skeleton width="60px" />
                                    </td>
                                    <td>
                                        <Skeleton width="80px" />
                                    </td>
                                    <td>
                                        <Skeleton width="80px" />
                                    </td>
                                </tr>
                                <tr>
                                    {/* <td>Cyber Monkey #9</td> */}
                                    <td style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                        <Skeleton width="100px" />
                                        <Skeleton width="60px" />
                                    </td>
                                    <td>
                                        <Skeleton width="60px" />
                                    </td>
                                    <td>
                                        <Skeleton width="80px" />
                                        <Skeleton width="60px" />
                                    </td>
                                    <td>
                                        <Skeleton width="80px" />
                                        <Skeleton width="60px" />
                                    </td>
                                    <td>
                                        <Skeleton width="80px" />
                                        <Skeleton width="60px" />
                                    </td>
                                    <td>
                                        <Skeleton width="80px" />
                                    </td>
                                    <td>
                                        <Skeleton width="80px" />
                                    </td>
                                </tr>
                                <tr>
                                    {/* <td>Cyber Monkey #9</td> */}
                                    <td style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                        <Skeleton width="100px" />
                                        <Skeleton width="60px" />
                                    </td>
                                    <td>
                                        <Skeleton width="60px" />
                                    </td>
                                    <td>
                                        <Skeleton width="80px" />
                                        <Skeleton width="60px" />
                                    </td>
                                    <td>
                                        <Skeleton width="80px" />
                                        <Skeleton width="60px" />
                                    </td>
                                    <td>
                                        <Skeleton width="80px" />
                                        <Skeleton width="60px" />
                                    </td>
                                    <td>
                                        <Skeleton width="80px" />
                                    </td>
                                    <td>
                                        <Skeleton width="80px" />
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
        </SkeletonTheme>
    )
}

export default TransactionLoader