import { useWallet } from '@solana/wallet-adapter-react';
import { useCallback, useState } from 'react';
import { useWalletModal } from "../Components/Common/WalletAdapterUi/useWalletModal";
import { Link } from 'react-router-dom';
import Layout from '../Components/Common/Layout/MainLayout';
import Banner from '../Components/Common/Explore/Banner';
import { useTranslation } from 'react-i18next';
import ReactCardFlip from 'react-card-flip';

export const SignUp = () => {
    return (

        <Layout>

            <SignUpButton />
        </Layout>

    )
}


const SignUpButton = (props) => {
    const { wallet, connect, connected } = useWallet();
    const { setVisible } = useWalletModal();
    const open = useCallback(() => setVisible(true), [setVisible]);
    const [isFlipped, setIsFlipped] = useState(false);
    const [showExplore, setShowExplore] = useState(false);

    const handleClick = useCallback(
        () => (wallet ? connect().catch(() => { }) : open()),
        [wallet, connect, open]
    );


    const phantomLink = "https://www.phantom.app";


    const phantomInstalled = window?.solana?.isPhantom;


    const { t } = useTranslation();

    const handleHover = () => {
        setIsFlipped(!isFlipped);
    }

    const handleHoverExplore = () => {
        setShowExplore(!showExplore);
    }


    if (phantomInstalled && connected) {
        return (
            <>
                <Banner title={t('choose_next_step')} />
                <section className="row justify-content-center navbar-wrap" style={{ minHeight: '800px', justifyContent: 'center' }}>
                    <div className="row justify-content-center col-xl-3 col-lg-4 col-md-5 col-sm-6 col-10">
                        <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal" containerStyle={{ height: '270px', width: '270px' }}>
                            <button onMouseOver={() => { setIsFlipped(true) }} style={{ background: 'none', border: 'none' }}><img src={"assets/img/cards/card.png"} height="270px" width="200px" /></button>
                            {/* <Link to='/mint-nft'> */}
                            <Link to='/select-type'>
                                <button onMouseLeave={() => { setIsFlipped(false) }} style={{ background: 'none', border: 'none' }}>
                                    <img src={"assets/img/cards/create.png"} height="270px" width="200px" />
                                    <h4 className="mt-3">Create an NFT</h4>
                                </button>
                            </Link>
                        </ReactCardFlip>
                    </div>
                    <div className="row justify-content-center col-xl-3 col-lg-4 col-md-5 col-sm-6 col-10">
                        <ReactCardFlip isFlipped={showExplore} flipDirection="horizontal" containerStyle={{ height: '270px', width: '270px' }}>
                            <button onMouseOver={() => { setShowExplore(true) }} style={{ background: 'none', border: 'none' }}><img src={"assets/img/cards/card.png"} height="270px" width="200px" /></button>
                            <Link to='/nft-marketplace'>
                                <button onMouseLeave={() => { setShowExplore(false) }} style={{ background: 'none', border: 'none' }}>
                                    <img src={"assets/img/cards/explore.png"} height="270px" width="270px" />
                                    <h4 className="mt-3">Explore NFTs</h4>
                                </button>
                            </Link>
                        </ReactCardFlip>
                    </div>
                    {/* <div className="mt-5 mx-5"></div> */}
                    {/* <Link to='/mint-nft'>
                        <button className="btn mt-5 mx-5"> {t('create_nft')} </button>
                    </Link>
                    <Link to='/nft-marketplace'>
                        <button className="btn mt-5 mx-5"> {t('explore_nfts')} </button>
                    </Link> */}
                </section>
            </>

        )
    } else {
        return (
            <>
                <Banner title={phantomInstalled && !connected ? t('have_phantom') : t('create_wallet')} />
                <section className="login-welcome-area" >
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="another-way-signup">

                                    ( {phantomInstalled ? <h4 className="titleNew">Connect Your Wallet Here!</h4> : <h4>A New Way to Sign Up!</h4>})
                                    <ul className="another-way-list">
                                        <li>
                                            {
                                                phantomInstalled ? <Link to="#" onClick={handleClick}><img src="assets/img/icons/phantomicon.png" style={{ width: '30px', borderRadius: '50%' }} alt="" />Phantom Wallet <span>Popular</span></Link> :
                                                    <a href={phantomLink} target="_blank" rel="noreferrer"><img src="assets/img/icons/phantomicon.png" style={{ width: '30px', borderRadius: '50%' }} alt="" />Phantom Wallet <span>Popular</span></a>
                                            }
                                            {/* <Link to={phantomInstalled ? "#" : phantomLink} target={phantomInstalled ? "" : "_blank"}><img src="assets3/img/icons/phantomicon.png" style={{width: '30px'}} alt=""/>Phantom Wallet <span>Popular</span></Link> */}
                                        </li >
                                        <li style={{ position: 'relative' }}><a href="/" style={{ filter: 'blur(2px)', pointerEvents: 'none', cursor: 'default' }} ><img src="assets/img/icons/flarelogo.png" style={{ width: '30px', borderRadius: '50%' }} alt="" />Solflare Wallet</a></li>
                                        <li style={{ position: 'relative' }}><a href="/" style={{ filter: 'blur(12px)', pointerEvents: 'none', cursor: 'default' }} ><img src="assets/img/icons/signup_icon02.png" alt="" />Secret Wallet</a><h4 className="titleNew" style={{ fontFamily: 'var(--font-family-heading)', position: 'absolute', zIndex: '99999px', color: 'white', top: '50%', transform: 'translate(-50%, -190%)', left: '50%' }}>More Wallets? Coming Soon.</h4></li>
                                    </ul >
                                </div >
                            </div >
                        </div >
                    </div >
                </section >
            </>
        )
    }


}