import React from "react";
import Category from "../Components/Common/Category/Category";
import Banner from "../Components/Common/Explore/Banner";
import Layout from "../Components/Common/Layout/MainLayout";
import ActivityPage from "../Components/Common/InnerPages/ActivityPage";

const Activity = () => {
  return (
    <>
      <Layout>
        <Banner title="NFT Activity" />
        <Category />
        <ActivityPage />
      </Layout>
    </>
  );
};

export default Activity;
