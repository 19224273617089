import React from "react";
import Category from "../Components/Common/Category/Category";
import Banner from "../Components/Common/Explore/Banner";
import Layout from "../Components/Common/Layout/MainLayout";
import LoginPage from "../Components/Common/InnerPages/LoginPage";

const LoginRegister = () => {
  return (
    <>
      <Layout>
        <Banner title="Registration & Sign In" />
        <Category />
        <LoginPage />
      </Layout>
    </>
  );
};

export default LoginRegister;
