import React from "react";
import Layout from "../Components/Common/Layout/MainLayout";
import WeekFeatures from "../Components/Common/IndexOne/WeekFeatures";
import Breadcrumb from "../Components/Common/InnerPages/marketsingle/Breadcrumb";
import SingleMarket from "../Components/Common/InnerPages/marketsingle/SingleMarket";

const MarketSingle = () => {
  return (
    <>
      <Layout>
        <Breadcrumb />
        <SingleMarket />
        <WeekFeatures />
      </Layout>
    </>
  );
};

export default MarketSingle;
