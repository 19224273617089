import React, { useState, useEffect } from 'react';
import Scroll from './Scroll';
import SearchList from './SearchList';
import { useParams } from "react-router";
// import OutsideClickHandler from 'react-outside-click-handler';
import { useTranslation } from "react-i18next";
import i18n from "i18next";

const OutsideClickHandler = require("react-outside-click-handler").default

function Search({ details }) {


  const [searchField, setSearchField] = useState("");
  const [searchShow, setSearchShow] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    setSearchShow(false);
    setSearchField("")
  }, [id])

  const { t } = useTranslation();

  const filteredCollection = details.filter(
    collection => {

      return (
        collection
          .name
          .toLowerCase()
          .includes(searchField.toLowerCase())
      );
    }
  );

  const handleChange = e => {
    setSearchShow(false);
    setSearchField(e.target.value);

    if (e.target.value === "") {
      setSearchShow(false);
      setSearchField("")

    }
    else {
      setSearchShow(true);
    }
  };

  function searchList() {
    if (searchShow) {
      return (
        <Scroll>
          <SearchList
            filteredCollection={filteredCollection}
          />
        </Scroll>

      );
    }
  }

  return (
    <>
      <OutsideClickHandler
        onOutsideClick={() => {
          setSearchShow(false);
          setSearchField("")

        }}
      >

        <section
          className="garamond" style={{ position: 'relative' }}
        >
          <div //className="pa2"  
          >
            <form //className="form-search"
            >
              <input
                // className="pa3 bb br3 grow b--none bg-lightest-blue ma3"
                // type="text" //"search"
                type="search"
                placeholder={t('search_collections')}
                onChange={handleChange}
                value={searchField}
              // class="search-click"
              // style={{ color: theme === 'light' ? '#000' : '#fff' }}
              />
              <button>
                <i style={{ color: '#fff' }} class="flaticon-search"></i>
              </button>
            </form>
          </div>

          {searchList()}

        </section>
      </OutsideClickHandler>
    </>
  );
}

export default Search;
