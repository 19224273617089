
import Card from './Card';


function SearchList({ filteredCollection }) {
  const filteredResult = filteredCollection.map(collection => <Card key={collection.id} collection={collection} />);
  return (
    <div >
      {filteredResult}
    </div>
  );
}

export default SearchList;
