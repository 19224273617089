import Modal from "react-bootstrap/Modal";
import "./ListNFTModal.css";
import { useForm } from "react-hook-form";
import { useState } from 'react';

export const ListNFTModal = ({
  showListingModal,
  setShowListingModal,
  listMyNft,
  data,
  isNftListing,
}) => {
  const isNotZero = (price) => price > 0;
  const handleClose = () => setShowListingModal(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    listMyNft(data);
  };

  return (
    <>
      <Modal
        show={showListingModal}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{data.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src={data.uri} alt="NFT" className="nft-image-modal" />

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="d-flex justify-content-around align-items-center">
              <label htmlFor="price">Price (SOL)</label>
              <div>
                <input
                  type="number"
                  step="any"
                  style={{ maxWidth: "184px" }}
                  id="price"
                  name="price"
                  inputMode="numeric"
                  {...register("price", { required: true, validate: isNotZero })}
                />
                {errors.price && (
                  <span className="input-error-text">
                    Kindly input a listing price
                  </span>
                )}
              </div>
            </div>

            <button
              className="btn d-block m-auto mt-4 mb-3"
              style={{ minWidth: "180px" }}
              type="submit"
              disabled={isNftListing}
            >
              {isNftListing ? "Listing in Progress..." : "Submit"}
            </button>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};
