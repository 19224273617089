import { useEffect, useRef } from 'react';
import { createAvatar } from '@dicebear/avatars';
import * as styler from '@dicebear/avatars-identicon-sprites';

export const Identicon = (props) => {
    const { style, className, alt } = props;
    const address =
        typeof props.address === 'string'
            ? props.address
            : props.address?.toBase58();
    const ref = useRef();
    const svg = createAvatar(styler, {
        seed: address,
        colors: ['blue', 'blueGrey', 'cyan', 'indigo', 'teal'],
        // ... and other options
    });
    const svg64 = btoa(unescape(encodeURIComponent(svg)));
    const image64 = 'data:image/svg+xml;base64,' + svg64;
    const imgEl = document.createElement('img');
    imgEl.src = image64;

    useEffect(() => {
        if (address && ref.current) {
            try {
                ref.current.innerHTML = '';
                ref.current.className = className || '';
                ref.current.appendChild(imgEl);
            } catch (err) {
                // TODO
            }
        }
    }, [address, style, className]);

    return (
        <div
            className="identicon-wrapper"
            title={alt}
            ref={ref}
            style={props.style}
        />
    );
};