/* eslint-disable */
import React, { useRef, useState } from 'react'
import NewsButton from '../../../pages/NewsButton'
import Banner from '../Explore/Banner'
import { Button } from '../WalletAdapterUi/Button'
import $ from 'jquery';
import { ToastContainer, toast, cssTransition } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export default function BlogDetailsContent() {
  const section1 = useRef(null)
  const section2 = useRef(null)
  const section3 = useRef(null)
  const [formValues, setformValues] = useState({
    author: "",

    mail: "",

    textarea: "",
  });
  const [formErrors, setFormErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setformValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let errorObj = validate(formValues);
    if (Object.keys(errorObj).length == 0) {
      // console.log(formValues); //submit
      toast.success(<div className="text">Successfully send the message</div>, { autoClose: 6000 },
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,

        })
      setformValues({

        mail: "",
        author: "",
        textarea: ""
      }
      );
      setFormErrors({})
    } else {
      // console.log(errorObj)
      setFormErrors(errorObj)
      Object.keys(errorObj).map(function (key, index) {
        $(`#${key}`).addClass('shakeEffect')
        $(`#${key}`).on('webkitAnimationEnd oanimationend msAnimationEnd animationend', function (e) {
          $(`#${key}`).delay(200).removeClass('shakeEffect');

        });
      })
    }

  }



  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    // const regex1 = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i;
    if (!values.author) {
      errors.author = "Author is required! ";
    }

    if (!values.mail) {
      errors.mail = "Email is required! ";
    } else if (!regex.test(values.mail)) {
      errors.mail = "This is not valid email format! ";
    }


    if (!values.textarea) {
      errors.textarea = "Message is required! "
    }
    return errors;
  };






  return (

    <section className="blog-details-area pt-80 pb-80">


      <div className='btn-header'>
        <button className='grid-item intro' id="btn" onClick={() => section1.current.scrollIntoView()} ><p className='intro-blog-p'><button className='btn-item2'>1</button>&nbsp;&nbsp;Introduction</p></button>
        <button className='grid-item intro' id="btn" onClick={() => section2.current.scrollIntoView()} ><p className='intro-blog-p'><button className='btn-item2'>2</button>&nbsp;&nbsp;Ownership&nbsp;</p></button>
        <button className='grid-item intro' id="btn" onClick={(() => section3.current.scrollIntoView())} ><p className='intro-blog-p'><button className='btn-item2'>3</button>&nbsp;&nbsp;Why Solana</p></button>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-xxl-9 col-lg-8">
            <br />
            <div className="standard-blog-item blog-details-content">
              <div className="blog-thumb">
                <img ref={section1} src="assets/img/blog/nft-intro-1.png" alt="" />
              </div>
              <div className="standard-blog-content">
                <ul className="standard-blog-meta">
                  <li><h6><a><i className="flaticon-supermarket" />NFT Marketplace</a></h6></li>
                  <li><h6><a><i className="flaticon-avatar" />Admin</a></h6></li>
                  <li><h6><a><i className="flaticon-calendar" />Jun 5, 2022</a></h6></li>
                </ul>
                <h4 className="title">Introduction to NFT's on Solana</h4>
                <p>
                  NFT stands for "non-fungible token." To put it simply, it is a one-of-a-kind digital asset.
                  When you own an NFT, you have the option of storing it as a
                  long-term investment or simply because you enjoy the art
                  You can also sell it and profit from it. NFTs provide
                  Access to creator royalties for artists
                  <br /><br />
                  The creator receives the agreed-upon preset
                  royalties for each NFT sold.
                  There are numerous NFT use cases being investigated in
                  games, music, real estate, and other fields!
                  It is necessary to establish proof of ownership for digital art.
                  a popular NFT application that provides artists with new opportunities.
                </p>
                <blockquote>
                  “ NFT's are birth certificates for the offspring of creators“
                  <footer>Dane Scarborough ~Actor & Producer</footer>
                </blockquote>
                <img id='nft-img' ref={section2} src="assets/img/blog/ownership-1.png" alt="" />
                <br /><br />

                <h4 className="title">What is proof of ownership?</h4>
                <p>
                  You can see who owns an NFT on a blockchain. If you owned an NFT, everyone on the planet would know that it belonged to your specific wallet address.
                  and no one can deny your ownership claim.
                  Anyone can download a Mona Lisa image from Google Images, but not everyone can own the Mona Lisa. This is one of the benefits of digital ownership.
                </p>
                <br /><br />
                <img id='nft-img' ref={section3} src="assets/img/blog/whysolana-1 .png" alt="" />

                <br /><br />

                <h4 className="title">Why to choose Solana?</h4>
                <br /><br />
                <p>
                  You might have heard that the best place to buy NFTs are on
                  Ethereum. When you buy an NFT on Ethereum, you need to pay
                  gas fees in order for your transaction to be processed.
                  Ethereum gas fees can easily cost more than the
                  NFT you wish to mint or buy. On Solana, transaction fees are
                  a fraction of a cent. <br /><br />
                  And, it’s lightning fast! Not to mention, the
                  environmental cost of 1 transaction on Solana
                  is less than 2 Google searches.
                  For more information on Solana’s energy
                  use, check out <a href="https://solana.com/news/solana-energy-usage-report-november-2021">solana.com</a>
                </p>
                <div className="blog-line" />
                <div className="blog-details-bottom">
                  <div className="blog-details-tags">
                    <ul>
                      <li className="title" >Tags :</li>
                      <li><a><h6>Business,</h6></a></li>
                      <li><a><h6>Work,</h6></a></li>
                      <li><a><h6>Knowledge,</h6></a></li>
                      <li><a><h6>Online</h6></a></li>
                    </ul>
                  </div>
                  <div className="blog-details-social">
                    <ul>
                      <li>
                        <a href="https://www.instagram.com/nft_plus.me/" target="_blank" rel="noreferrer">

                          <i className="fab fa-instagram" />
                        </a>
                      </li>

                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <br /><br />
            {/* <div className="blog-next-prev">
              <ul>
                <li className="blog-prev">
                  <Button id='post-button'><img src="assets/img/icons/left_arrow.png" alt="img" />Previous Post</Button>
                </li>
                <li className="blog-next">
                  <Button id='post-button'>Next Post<img src="assets/img/icons/right_arrow.png" alt="img" /></Button>
                </li>
              </ul>
            </div> */}
            {/* <div className="comment-reply-box">
              <h5 className="title">Leave a Reply</h5>
              <form onSubmit={handleSubmit} className="comment-reply-form">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-grp">
                      <input type="text"
                        name="author"
                        placeholder="Author"

                        value={formValues.author}
                        onChange={handleChange}
                      />


                      <p id='author' className="errors_message">{formErrors.author}</p>


                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-grp">
                      <input type="text" placeholder="Your Email *"
                        placeholder="Email"
                        name="mail"
                        value={formValues.mail}
                        onChange={handleChange}
                      />
                      <p id='mail' className="errors_message">{formErrors.mail}</p> </div>
                  </div>
                </div>
                <div className="form-grp">
                  <textarea name="message" placeholder="Type Comment Here..." defaultValue={""}
                    name="textarea"
                    value={formValues.textarea}
                    onChange={handleChange}

                  />
                  <p id="textarea" className="errors_message">{formErrors.textarea}</p>

                </div>
                <button type="submit" className="btn">Submit now</button>
              </form>
            </div> */}
          </div>
          {/* <div className="col-xxl-3 col-lg-4">
            <aside className="blog-sidebar">
              <div className="widget">
                <h4 className="sidebar-title">Search</h4>
                <div className="sidebar-search">
                  <form action="#">
                    <input type="text" placeholder="Search ..." />
                    <button type="submit"><i className="fa fa-search" /></button>
                  </form>
                </div>
              </div>
              <div className="widget">
                <h4 className="sidebar-title">Categories</h4>
                <div className="sidebar-cat-list">
                  <ul>
                    <li><a href="/#">Domain Names <i className="fas fa-angle-double-right" /></a></li>
                    <li><a href="/#">Photography <i className="fas fa-angle-double-right" /></a></li>
                    <li><a href="/#">Coinbase <i className="fas fa-angle-double-right" /></a></li>
                    <li><a href="/#">Trading Cards <i className="fas fa-angle-double-right" /></a></li>
                  </ul>
                </div>
              </div>
              <div className="widget">
                <h4 className="sidebar-title">Recent Post</h4>
                <div className="rc-post-list">
                  <ul>
                    <li>
                      <div className="rc-post-thumb">
                        <a href="/blog-details"><img src="assets/img/blog/rc_post_thumb01.jpg" alt="" /></a>
                      </div>
                      <div className="rc-post-content">
                        <ul className="standard-blog-meta">
                          <li><a href="/#"><i className="flaticon-avatar" />Admin</a></li>
                          <li><a href="/#"><i className="flaticon-calendar" />Mar 10, 2022</a></li>
                        </ul>
                        <h5 className="title"><a href="/blog-details">Marketplace is the online shop for the video</a></h5>
                      </div>
                    </li>
                    <li>
                      <div className="rc-post-thumb">
                        <a href="/blog-details"><img src="assets/img/blog/rc_post_thumb02.jpg" alt="" /></a>
                      </div>
                      <div className="rc-post-content">
                        <ul className="standard-blog-meta">
                          <li><a href="/#"><i className="flaticon-avatar" />Admin</a></li>
                          <li><a href="/#"><i className="flaticon-calendar" />Mar 10, 2022</a></li>
                        </ul>
                        <h5 className="title"><a href="/blog-details">Marketplace is the online shop for the video</a></h5>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </aside>
          </div> */}
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover

      />
    </section>
  )
}
